import web3 from "./web3";
var instance;
export var ca = "0xb653E348484698A5af5cCc7Bc0b97AC3411Ff3f8"; //"0xd61c8B45978E716699b25aBeeCEBb644647597A6";
var abi = [
  {
    inputs: [],
    name: "acceptOwnership",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "_BETValue",
        type: "uint256",
      },
      {
        internalType: "uint256",
        name: "_ETHprice",
        type: "uint256",
      },
      {
        internalType: "string",
        name: "_logoHash",
        type: "string",
      },
      {
        internalType: "string",
        name: "_Desc",
        type: "string",
      },
      {
        internalType: "uint256",
        name: "_expDate",
        type: "uint256",
      },
      {
        internalType: "bool",
        name: "_isDonation",
        type: "bool",
      },
      {
        internalType: "address",
        name: "_tokenAddress",
        type: "address",
      },
      {
        internalType: "string",
        name: "_tokenName",
        type: "string",
      },
    ],
    name: "createGiftCard",
    outputs: [],
    stateMutability: "payable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "tokenAddress",
        type: "address",
      },
      {
        internalType: "uint256",
        name: "_amount",
        type: "uint256",
      },
    ],
    name: "depositTokens",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "_autobetUseraddress",
        type: "address",
      },
    ],
    stateMutability: "nonpayable",
    type: "constructor",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: "uint256",
        name: "giftCardId",
        type: "uint256",
      },
      {
        indexed: true,
        internalType: "address",
        name: "creator",
        type: "address",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "BETValue",
        type: "uint256",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "ETHPrice",
        type: "uint256",
      },
      {
        indexed: false,
        internalType: "string",
        name: "logoHash",
        type: "string",
      },
      {
        indexed: false,
        internalType: "string",
        name: "Desc",
        type: "string",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "expDate",
        type: "uint256",
      },
      {
        indexed: false,
        internalType: "address",
        name: "tokenAddress",
        type: "address",
      },
    ],
    name: "GiftCardCreated",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: "address",
        name: "giftCardCreator",
        type: "address",
      },
      {
        indexed: true,
        internalType: "uint256",
        name: "giftCardId",
        type: "uint256",
      },
      {
        indexed: true,
        internalType: "address",
        name: "buyer",
        type: "address",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "BETAmount",
        type: "uint256",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "ETHAmount",
        type: "uint256",
      },
    ],
    name: "GiftCardPurchased",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: "address",
        name: "from",
        type: "address",
      },
      {
        indexed: true,
        internalType: "address",
        name: "to",
        type: "address",
      },
    ],
    name: "OwnershipTransferRequested",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: "address",
        name: "from",
        type: "address",
      },
      {
        indexed: true,
        internalType: "address",
        name: "to",
        type: "address",
      },
    ],
    name: "OwnershipTransferred",
    type: "event",
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "_giftCardId",
        type: "uint256",
      },
      {
        internalType: "string",
        name: "_voucherCode",
        type: "string",
      },
    ],
    name: "purchaseGiftCard",
    outputs: [],
    stateMutability: "payable",
    type: "function",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: "address",
        name: "sender",
        type: "address",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "value",
        type: "uint256",
      },
    ],
    name: "Received",
    type: "event",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "to",
        type: "address",
      },
    ],
    name: "transferOwnership",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "amount",
        type: "uint256",
      },
      {
        internalType: "address",
        name: "to",
        type: "address",
      },
      {
        internalType: "address",
        name: "tokenAdd",
        type: "address",
      },
    ],
    name: "transferToken",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "_giftCardId",
        type: "uint256",
      },
    ],
    name: "withdrawCreatorTokens",
    outputs: [],
    stateMutability: "payable",
    type: "function",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: "uint256",
        name: "giftCardId",
        type: "uint256",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "amount",
        type: "uint256",
      },
      {
        indexed: false,
        internalType: "address",
        name: "creator",
        type: "address",
      },
    ],
    name: "withdrawedTokens",
    type: "event",
  },
  {
    inputs: [],
    name: "withdrawETH",
    outputs: [],
    stateMutability: "payable",
    type: "function",
  },
  {
    stateMutability: "payable",
    type: "receive",
  },
  {
    inputs: [],
    name: "autobetUseraddress",
    outputs: [
      {
        internalType: "address",
        name: "",
        type: "address",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "getContractETHBalance",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "_tokenAdd",
        type: "address",
      },
    ],
    name: "getContractTokenBalance",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "_tokenAddress",
        type: "address",
      },
      {
        internalType: "address",
        name: "_walletAddress",
        type: "address",
      },
    ],
    name: "getERC20TokenBalance",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "_buyer",
        type: "address",
      },
    ],
    name: "getGiftCardsByBuyer",
    outputs: [
      {
        components: [
          {
            internalType: "uint256",
            name: "giftCardId",
            type: "uint256",
          },
          {
            internalType: "address",
            name: "creator",
            type: "address",
          },
          {
            internalType: "uint256",
            name: "BETValue",
            type: "uint256",
          },
          {
            internalType: "uint256",
            name: "ETHPrice",
            type: "uint256",
          },
          {
            internalType: "string",
            name: "logoHash",
            type: "string",
          },
          {
            internalType: "string",
            name: "Desc",
            type: "string",
          },
          {
            internalType: "uint256",
            name: "expDate",
            type: "uint256",
          },
          {
            internalType: "enum GiftCardMarketplace.giftCardStatus",
            name: "status",
            type: "uint8",
          },
          {
            internalType: "bool",
            name: "isSold",
            type: "bool",
          },
          {
            internalType: "address",
            name: "buyer",
            type: "address",
          },
          {
            internalType: "bool",
            name: "isDonation",
            type: "bool",
          },
          {
            internalType: "string",
            name: "voucherCode",
            type: "string",
          },
          {
            internalType: "address",
            name: "tokenAddress",
            type: "address",
          },
          {
            internalType: "string",
            name: "tokenName",
            type: "string",
          },
        ],
        internalType: "struct GiftCardMarketplace.GiftCard[]",
        name: "",
        type: "tuple[]",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "giftCardId",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
    ],
    name: "giftCards",
    outputs: [
      {
        internalType: "uint256",
        name: "giftCardId",
        type: "uint256",
      },
      {
        internalType: "address",
        name: "creator",
        type: "address",
      },
      {
        internalType: "uint256",
        name: "BETValue",
        type: "uint256",
      },
      {
        internalType: "uint256",
        name: "ETHPrice",
        type: "uint256",
      },
      {
        internalType: "string",
        name: "logoHash",
        type: "string",
      },
      {
        internalType: "string",
        name: "Desc",
        type: "string",
      },
      {
        internalType: "uint256",
        name: "expDate",
        type: "uint256",
      },
      {
        internalType: "enum GiftCardMarketplace.giftCardStatus",
        name: "status",
        type: "uint8",
      },
      {
        internalType: "bool",
        name: "isSold",
        type: "bool",
      },
      {
        internalType: "address",
        name: "buyer",
        type: "address",
      },
      {
        internalType: "bool",
        name: "isDonation",
        type: "bool",
      },
      {
        internalType: "string",
        name: "voucherCode",
        type: "string",
      },
      {
        internalType: "address",
        name: "tokenAddress",
        type: "address",
      },
      {
        internalType: "string",
        name: "tokenName",
        type: "string",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "owner",
    outputs: [
      {
        internalType: "address",
        name: "",
        type: "address",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
];
if (web3) {
  instance = new web3.eth.Contract(abi, ca);
}
export default instance;
