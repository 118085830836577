import React, { useEffect, useState } from "react";
import { Wheel } from "react-custom-roulette";
import { Container, Col, Row, Form, Modal, Button } from "react-bootstrap";
import "./SpinWheelStyles.css";
import web3 from "../../Service/web3";
import getImageUrlByHash from "../../../static/logoUtils";
import cinstance from "../../Service/randomcinstance";
import AutoLogo from "../../../static/Images/logo.png";
import { Path } from "../../../static/Constants";
import { useHistory } from "react-router";
import Loaders from "../Loaders";
import FailedPopup from "../FailedPopup";
import moment from "moment";

var randomColor = require("randomcolor");

// const data = [
//   { option: "0" },
//   { option: "1" },
//   { option: "2" },
//   { option: "0" },
//   { option: "1" },
//   { option: "2" },
//   { option: "0" },
//   { option: "1" },
//   { option: "2" },
//   { option: "0" },
//   { option: "1" },
//   { option: "2" },
//   { option: "0" },
//   { option: "1" },
//   { option: "2" },
// ];

export default ({ lotteryData, partner, partnerLogo }) => {
  const [data, setData] = useState([]);
  const [mustSpin, setMustSpin] = useState(false);
  const [blockno, setblockno] = useState(0);
  const [selectedNo, setselectedNo] = useState("");
  const [spinresult, setspinresult] = useState("");
  const [modalsshow, setmodalsshow] = useState(false);
  const [showFail, setShowFail] = useState(false);
  const [msgs, setmsg] = useState("");
  const [partnerlist, setPartnerlist] = useState([]);
  const [showResultMesg, setshowResult] = useState(false);

  const [message, setMessage] = useState("");
  const [startspinning, setstartspinning] = useState(false);
  const [play, setplay] = useState("");
  const [spincolors, setspincolors] = useState([
    randomColor(),
    randomColor(),
    randomColor(),
    randomColor(),
    randomColor(),
    randomColor(),
    randomColor(),
    randomColor(),
    randomColor(),
    randomColor(),
    randomColor(),
    randomColor(),
    randomColor(),
    randomColor(),
    randomColor(),
    randomColor(),
    randomColor(),
    randomColor(),
    randomColor(),
    randomColor(),
    randomColor(),
    randomColor(),
  ]);
  const imageUrl = getImageUrlByHash(partner?.logoHash, partnerLogo);

  useEffect(() => {
    let val = [];
    for (var i = 1; i <= lotteryData.capacity; i++) {
      val.push({ option: i });
    }

    setData(val);
    web3.eth.getAccounts().then((account) => {});
  }, []);

  let history = useHistory();

  const callresult = (event) => {
    if (event.returnValues.winnerNum == event.returnValues.selectedNum)
      alert("Congratulations! You're the lucky winner of the lottery!");
    else
      alert(`Winner = ${event.returnValues.winnerNum}, Better luck next time `);
  };

  const rolling = async () => {
    setplay("rollspin");
    setstartspinning(false);
    setspinresult(-1);

    setstartspinning(true);
    /*
  setTimeout(() => {
  
   rolling();
},9000);*/
  };

  const handleSpinClick = async () => {
    let lotteryDates = [];
    var partnerlists = [];
    if (selectedNo != "") {
      if (window.ethereum) {
        setMustSpin(true);
        rolling();
        window.web3 = web3;
        //this.setState({ loading: true });
        let account = await web3.eth.getAccounts();
        if (account.length != 0)
          cinstance.methods
            .buySpinnerLottery(selectedNo, lotteryData.lotteryId)
            .send({ from: account[0], value: lotteryData.entryFee })
            .then((res) => {
              setshowResult(true);
              setMessage(
                "The result is currently being generated. Please wait for 1-2 minutes while the process completes."
              );
              setTimeout(() => {
                cinstance.methods
                  .lottery(lotteryData.lotteryId)
                  .call()
                  .then((response) => {
                    setshowResult(false);
                    cinstance.getPastEvents(
                      "SpinLotteryResult",
                      {
                        filter: { useraddress: `${account[0]}` },
                        fromBlock: res.blockNumber,
                        toBlock: "latest",
                      },
                      (errors, events) => {
                        if (!errors) {
                          if (
                            events &&
                            events[0] &&
                            events[0].returnValues.winnerNum == events &&
                            events[0] &&
                            events[0].returnValues.selectedNum
                          ) {
                            setmsg(
                              "Congratulations! You're the lucky winner of the lottery!"
                            );
                            setmodalsshow(true);
                          } else {
                            var num =
                              events &&
                              events[0] &&
                              events[0].returnValues.winnerNum;
                            if (num == selectedNo) {
                              setmsg(
                                "Congratulations! You're the lucky winner of the lottery!"
                              );
                              setmodalsshow(true);
                            } else {
                              setmsg(
                                `Better luck next time. Winning Number is ${num}`
                              );
                              setmodalsshow(true);

                              setspinresult(num - 1);
                            }
                          }
                        }
                      }
                    );

                    setMustSpin(false);
                    setstartspinning(false);
                    setstartspinning(true);

                    setplay("");
                  });
              }, 60000);

              console.log(res.blockNumber);
            })
            .catch((er) => {
              setMustSpin(false);
              setstartspinning(false);
              setplay("");
              setShowFail(true);
            });
        else {
          window.ethereum.enable();
        }
      }
    }
  };

  return (
    <div className={"wheelarea " + play}>
      <Wheel
        mustStartSpinning={startspinning}
        prizeNumber={spinresult}
        data={data}
        textColors={["#fff"]}
        backgroundColors={spincolors}
        outerBorderWidth={0}
        innerRadius={70}
        innerBorderWidth={0}
        radiusLineWidth={0}
        perpendicularText={false}
        textDistance={85}
        outerBorderColor={"grey"}
        innerBorderColor={"grey"}
        radiusLineColor={"grey"}
        onStopSpinning={() => {
          console.log(spinresult);
          if (spinresult == -1) setstartspinning(false);
          setstartspinning(true);
        }}
      />
      {showResultMesg && <p className="bottomtextSpinner">{message}</p>}
      <Modal
        className="PopupStyle RevolverPopup"
        show={modalsshow}
        centered
        onHide={() => {
          setmodalsshow(false);
          history.push(`/`);
          window.location.reload();
        }}
      >
        <Modal.Body>
          <Container>
            <Row>
              <Col lg={12}>
                <div className="message-text">{msgs}</div>
              </Col>
              <Col lg={12} sm={12} xs={12}>
                <div className="btn-div">
                  <Button
                    className="submit-btn"
                    onClick={() => {
                      setmodalsshow(false);
                      history.push(`/`);
                      window.location.reload();
                    }}
                  >
                    Close
                  </Button>
                </div>
              </Col>
            </Row>
          </Container>
        </Modal.Body>
      </Modal>

      <FailedPopup
        show={showFail}
        setShowModal={(e) => setShowFail(e)}
        lotteryType={"revolver"}
      />

      <Form.Control
        required
        type="number"
        value={selectedNo}
        onChange={(e) => {
          if (Number(e.target.value) > 0) {
            setselectedNo(e.target.value);
          } else if (e.target.value == "") {
            setselectedNo(e.target.value);
          }
        }}
        placeholder={`1 - ${lotteryData.capacity}`}
        className="tbx"
      />

      <div className="details">
        {(partner?.logoHash !== undefined || partner?.logoHash !== "") && (
          <img src={imageUrl} className="partnerlogo" />
        )}
        <h4 className="amount-text lotteryId">
          {moment
            .unix(lotteryData && lotteryData.deployedOn)
            .format("DDMMYYYY")}
          {""}-{lotteryData.lotteryId}
        </h4>{" "}
        <h3>Prize</h3>
        <h4 className="amount-text">
          {web3.utils.fromWei(`${lotteryData.totalPrize}`, "ether")}POL
        </h4>{" "}
        <h3>Entry Fee</h3>
        <h4 className="amount-text">
          {web3.utils.fromWei(`${lotteryData.entryFee}`, "ether")}POL
        </h4>
      </div>
      {Number(selectedNo) > 0 && Number(selectedNo) <= lotteryData.capacity && (
        <button onClick={() => handleSpinClick()} className="mybtn1 topspace">
          BUY
        </button>
      )}
      {lotteryData.status === "5" && (
        <div className="lottery-tag">
          <span>Rollover</span>
        </div>
      )}
    </div>
  );
};
