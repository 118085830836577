import React, { useState, useEffect } from "react";
import { Card, CardBody, Dropdown, Input } from "reactstrap";
import { Form, Row, Col, Container, Button, Modal } from "react-bootstrap";
import ButtonCommon from "../../Components/ButtonCommon";
import { useHistory } from "react-router-dom";
import axios from "axios";
import cinstance from "../../Service/randomcinstance";
import usercinstance from "../../Service/usercinstance";
import giftcardcinstance from "../../Service/giftcardcinstance";
import tokencinstance, { getTokenInstance } from "../../Service/tokencinstance";
import web3 from "../../Service/web3";
import OrgNavBar from "../../Components/OrgNavBar";
import DateTimePicker from "react-datetime-picker";
import moment from "moment";
import Warnings from "../../Components/Warning";
import Loaders from "../../Components/Loaders";
import FailedPopup from "../../Components/FailedPopup";
import SuccessPopup from "../../Components/SuccessPopup";
import BigNumber from "bignumber.js";
const CreateGiftCard = (props) => {
  const [amount, setAmount] = useState("");
  const [price, setPrice] = useState("");
  const [desc, setDesc] = useState("");
  const [file, setfile] = useState(null);
  let history = useHistory();
  const [error, seterror] = useState(false);
  const [modalOpen, setmodalOpen] = useState(false);
  const [validated, setValidated] = useState(false);
  const [loading, setloading] = useState(false);
  const [active, setactive] = useState(false);
  const [approved, setapproved] = useState(false);
  const defaultExpiry = new Date();
  defaultExpiry.setDate(defaultExpiry.getDate() + 7);
  const [expiry, setExpiry] = useState(defaultExpiry);
  const [show, setShow] = useState(true);
  const [address, setAddress] = useState("");
  const [tokenAddress, settokenAddress] = useState("");
  const [showFail, setShowFail] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);
  const [betBalance, setBetBalance] = useState("");
  const [giftcardType, setGiftCardType] = useState("0");
  const [isDonated, setIsDonated] = useState(false);
  const apiUrl = process.env.REACT_APP_API_KEY;

  const onSendmsg = async (event) => {
    const form = event.currentTarget;
    let val = true;
    event.preventDefault();
    event.stopPropagation();

    if (Number(amount) < 0) {
      val = false;
    }

    if (Number(price) < 0) {
      val = false;
    }

    if (Number(amount) === 0 || amount.trim() === "") {
      val = false;
    }

    if (isDonated === false && (Number(price) === 0 || price.trim() === "")) {
      val = false;
    }

    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    }
    setValidated(true);
    if (form.checkValidity() === true && val) {
      let currentDT = moment().unix();
      let expDate = moment(expiry).unix();
      let maxExpDate = moment().add(7, "days").unix();
      if (currentDT > expDate) {
        alert("Please enter a future expiration date.");
        event.preventDefault();
      } else if (expDate > maxExpDate) {
        alert("Expiry date cannot be more than 7 days from the current date.");
        event.preventDefault();
      } else {
        setloading(true);
        let priceValue = web3.utils.toWei(`${price}`, "ether");
        setactive(true);
        event.preventDefault();
        let account = await web3.eth.getAccounts();
        let formData = new FormData();
        formData.append("file", file);
        if (file !== null) {
          axios
            .post(
              "https://ipfs.infura.io:5001/api/v0/add?pin=false",
              formData,
              {
                headers: {
                  "Content-Type": `multipart/form-data`,
                },
                auth: {
                  username: "2Itj30QgBHxl9PizuBlwM6vhd77",
                  password: "271d404efd8c78baae273b4a7a5b6747",
                },
              }
            )
            .then((response) => {
              if (response.status == 200) {
                let logoHash = response?.data?.Hash;
                let newFormData = new FormData();
                newFormData.append("file", file);
                newFormData.append("logoHash", logoHash);
                axios
                  .post(
                    `${apiUrl}/giftcardlogos/uploadgiftcardLogo`,
                    newFormData,
                    {
                      headers: {
                        "Content-Type": "multipart/form-data",
                      },
                    }
                  )
                  .then(async (apiResponse) => {
                    // console.log("API Response", apiResponse);
                    let instance = getTokenInstance(tokenAddress);
                    instance.methods
                      .decimals()
                      .call()
                      .then((res) => {
                        let amountValue = 0;
                        if (res == 18) {
                          amountValue = web3.utils.toWei(`${amount}`, "ether");
                        } else {
                          amountValue = amount * 10 ** res;
                        }
                        instance.methods
                          .balanceOf(account[0])
                          .call()
                          .then((balance) => {
                            if (Number(balance) >= Number(amountValue)) {
                              instance.methods
                                .approve(
                                  giftcardcinstance._address,
                                  amountValue
                                )
                                .send({ from: account[0] })
                                .then((res) => {
                                  instance.methods
                                    .symbol()
                                    .call()
                                    .then((tokenName) => {
                                      giftcardcinstance.methods
                                        .createGiftCard(
                                          amountValue,
                                          priceValue,
                                          logoHash,
                                          desc,
                                          expDate,
                                          isDonated,
                                          tokenAddress,
                                          tokenName
                                        )
                                        .send({ from: account[0] })
                                        .then((res) => {
                                          setloading(false);
                                          setShowSuccess(true);
                                          setAmount(" ");
                                          setPrice(" ");
                                          setDesc(" ");
                                          setExpiry("");
                                          setfile(" ");
                                          setValidated(false);
                                        })
                                        .catch(() => {
                                          setloading(false);
                                          setShowFail(true);
                                        });
                                    })
                                    .catch((e) => {
                                      console.log("error1", e);
                                      setloading(false);
                                      setShowFail(true);
                                    });
                                })
                                .catch((e) => {
                                  console.log("error1", e);
                                  setloading(false);
                                  setShowFail(true);
                                });
                            } else {
                              alert("Low Token Balance");
                              setloading(false);
                            }
                          })
                          .catch((rr) => {
                            console.log({ rr });
                          });
                      })
                      .catch((rr) => {
                        setloading(false);
                        setShowFail(true);
                      });
                  })
                  .catch((apiError) => {
                    console.log("Api Error", apiError);
                    setloading(false);
                    setShowFail(true);
                  });
              }
            })
            .catch((e) => {
              console.log("error", e);
              setloading(false);
              setShowFail(true);
            });
        } else {
          alert("Select Card Image");
          setloading(false);
        }
      }
    } else {
      event.preventDefault();
      setloading(false);
      alert("All fields are mandatory");
    }
  };

  useEffect(() => {
    async function fetchData() {
      if (window.ethereum) {
        window.web3 = web3;
        let account = await web3.eth.getAccounts();
        window.ethereum.on("accountsChanged", () => {
          window.location.reload();
        });
        if (account.length !== 0) {
          setAddress(account[0]);
          setloading(true);
          usercinstance.methods
            .organisationbyaddr(account[0])
            .call()
            .then((res) => {
              setloading(false);
              setapproved(res);
            })
            .catch((err) => console.log("res", err));

          // giftcardcinstance.methods
          //   .getBetTokenBalance(account[0])
          //   .call()
          //   .then((res) => {
          //     setBetBalance(res);
          //   });
        } else {
          seterror(true);
          setloading(false);
        }
      } else {
        setloading(false);

        seterror(true);
      }
    }
    fetchData();
  }, []);

  useEffect(() => {
    if (giftcardType === "1") {
      setPrice("0");
      setIsDonated(true);
    } else {
      setIsDonated(false);
    }
  }, [giftcardType]);

  return (
    <div>
      <OrgNavBar />
      {error && <Warnings />}
      {loading && <Loaders />}

      <section
        className="register-section mspace"
        style={{ alignItems: "start", paddingTop: 50 }}
      >
        <div className="container  createLottery_container">
          {approved && !approved.active && !loading && (
            <h3 className="mt-5 text-left pl-5 ">Account Not Approved</h3>
          )}
          {approved && approved.active && (
            <div className="bgImage  p-o m-0" fluid>
              <Row>
                <Col lg="12">
                  <Card
                    className=" ccard Card-style"
                    style={{
                      margin: "auto",
                      borderRadius: 20,
                      padding: 20,
                      maxWidth: "550px",
                      boxShadow: "0 0 10px 0 rgb(0 0 0 / 15%)",
                    }}
                  >
                    <h1
                      style={{
                        marginTop: 30,
                        fontSize: "28px",
                        fontWeight: 600,
                        textAlign: "center",
                      }}
                    >
                      Create A New Gift Card
                    </h1>
                    <CardBody style={{ paddingBottom: 0 }}>
                      <Form
                        noValidate
                        validated={validated}
                        onSubmit={onSendmsg}
                        className="CreateLotteryForm"
                      >
                        <Form.Row>
                          <Form.Group
                            as={Col}
                            md="6"
                            controlId="validationCustom01"
                          >
                            <Form.Label className="mr-5">
                              Gift Card Type
                            </Form.Label>
                            <Form.Control
                              aria-label="Default select example"
                              as={"select"}
                              onChange={(event) => {
                                setGiftCardType(event.target.value);
                              }}
                            >
                              <option value={"0"}>Sell</option>
                              <option value={"1"}>Donate</option>
                            </Form.Control>
                          </Form.Group>
                          <Form.Group
                            as={Col}
                            md="6"
                            controlId="validationCustom01"
                          >
                            <Form.Label>Expiry Date</Form.Label>
                            <br />
                            <DateTimePicker
                              className="dats date-input gift-date"
                              format={"dd-MM-yy h:mma"}
                              calendarIcon={null}
                              clearIcon={null}
                              disableClock={true}
                              onChange={(value) => setExpiry(value)}
                              value={expiry}
                            />
                          </Form.Group>
                          <Form.Group
                            as={Col}
                            md="12"
                            controlId="validationCustom01"
                          >
                            <Form.Label>Token Address</Form.Label>
                            <Form.Control
                              required
                              placeholder="Token Address"
                              value={tokenAddress}
                              onChange={(event) => {
                                settokenAddress(event.target.value);
                              }}
                              isInvalid={
                                validated &&
                                (!web3.utils.isAddress(tokenAddress) ||
                                  tokenAddress.trim() === "")
                              }
                            />
                            <Form.Control.Feedback type="invalid">
                              {tokenAddress.trim() === ""
                                ? "Enter Token Address"
                                : !web3.utils.isAddress(tokenAddress)
                                ? "Enter Valid Token Address"
                                : "Enter Valid Token Address"}
                            </Form.Control.Feedback>
                          </Form.Group>
                          <Form.Group
                            as={Col}
                            md="6"
                            controlId="validationCustom01"
                          >
                            <Form.Label>Token Amount</Form.Label>
                            <Form.Control
                              required
                              type="number"
                              placeholder="Token Amount"
                              value={amount}
                              onChange={(event) => {
                                setAmount(event.target.value);
                              }}
                              isInvalid={
                                validated &&
                                (Number(amount) === 0 || amount.trim() === "")
                              }
                            />
                            <Form.Control.Feedback type="invalid">
                              {amount.trim() === ""
                                ? "Enter Amount"
                                : BigNumber(amount) < 0
                                ? "Amount cannot be negative"
                                : // : BigNumber(amount) >
                                //   parseFloat(
                                //     web3.utils.fromWei(`${betBalance}`, "ether")
                                //   )
                                // ? "Insufficient balance"
                                //
                                Number(amount) === 0
                                ? "Amount  cannot be 0"
                                : "Enter Amount"}
                            </Form.Control.Feedback>
                          </Form.Group>

                          {giftcardType !== "1" && (
                            <Form.Group
                              as={Col}
                              md="6"
                              controlId="validationCustom01"
                            >
                              <Form.Label>Selling Price </Form.Label>
                              <Form.Control
                                required
                                type="number"
                                placeholder="Price in POL "
                                value={price}
                                onChange={(event) => {
                                  setPrice(event.target.value);
                                }}
                                isInvalid={validated && price.trim() === ""}
                              />
                              <Form.Control.Feedback type="invalid">
                                {price.trim() === ""
                                  ? "Enter Price in POL"
                                  : BigNumber(price) < 0
                                  ? "Price amount cannot be negative"
                                  : Number(price) === 0
                                  ? "Price cannot be 0 for sell gift card"
                                  : "Enter Price in POL"}
                              </Form.Control.Feedback>
                            </Form.Group>
                          )}

                          <Form.Group
                            as={Col}
                            md="12"
                            controlId="validationCustom01"
                            className="textarea-input"
                          >
                            <Form.Label>Description</Form.Label>
                            <Form.Control
                              required
                              as="textarea"
                              placeholder="Enter Description "
                              value={desc}
                              rows={4}
                              onChange={(event) => {
                                setDesc(event.target.value);
                              }}
                            />
                            <Form.Control.Feedback type="invalid">
                              Enter Description
                            </Form.Control.Feedback>
                          </Form.Group>

                          <Form.Group
                            as={Col}
                            md="6"
                            controlId="validationCustom01"
                          >
                            <Form.Label>Card Image</Form.Label>
                            <Form.File
                              required
                              type="file"
                              accept="image/*"
                              onChange={(event) => {
                                setfile(event.target.files[0]);
                              }}
                              isInvalid={!file}
                              className="mt-1"
                            />
                            <Form.Control.Feedback type="invalid">
                              Select Card Image
                            </Form.Control.Feedback>
                          </Form.Group>
                        </Form.Row>

                        <div
                          style={{
                            margin: "auto",
                            textAlign: "center",
                            marginTop: "20px",
                          }}
                        >
                          <ButtonCommon
                            type="Submit"
                            className="signup-btn"
                            label="Create"
                          />
                        </div>
                      </Form>

                      <span className="d-inline-block mb-2 mr-2">
                        <Modal
                          className="PopupStyle"
                          show={modalOpen}
                          onHide={() => setmodalOpen(false)}
                          centered
                        >
                          <Modal.Body>
                            <Container>
                              <Row>
                                <Col lg={12}>
                                  {/* <div className="message-text"></div> */}
                                </Col>
                                <Col lg={12} sm={12} xs={12}>
                                  <div className="btn-div">
                                    <Button
                                      className="submit-btn"
                                      onClick={() => {
                                        window.location.reload();
                                        setmodalOpen(false);
                                      }}
                                    >
                                      OK
                                    </Button>
                                  </div>
                                </Col>
                              </Row>
                            </Container>
                          </Modal.Body>
                        </Modal>
                      </span>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </div>
          )}
        </div>
      </section>
      <SuccessPopup
        show={showSuccess}
        setShowModal={(e) => setShowSuccess(e)}
        message={"Gift card created successfully !"}
        redirectPath={"/creator/mygiftcard"}
        btnName={"Go to my gift cards"}
      />
      <FailedPopup show={showFail} setShowModal={(e) => setShowFail(e)} />
    </div>
  );
};

export default CreateGiftCard;
