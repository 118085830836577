import React, { Component } from "react";
import {
  Container,
  Row,
  Col,
  ListGroup,
  Card,
  CardDeck,
  CardColumns,
  Button,
} from "react-bootstrap";
import cinstance from "../../Service/randomcinstance";
import usercinstance from "../../Service/usercinstance";
import giftcardcinstance from "../../Service/giftcardcinstance";
import NavBar from "../../Components/NavBar";
import web3 from "../../Service/web3";
import OrgNavBar from "../../Components/OrgNavBar";
import moment from "moment";
import ButtonCommon from "../../Components/ButtonCommon";
import RegisterAsorganisation from "../User/RegisterAsOrganisation";
import { Path } from "../../../static/Constants";
import { networkID } from "../../../static/utils";
import { toast, ToastContainer } from "react-toastify";
import { FaRegCopy } from "react-icons/fa6";

class OrgHome extends Component {
  constructor(props) {
    super(props);
    this.state = {
      approved: { active: true },
      TotalOrganisation: 0,
      loading: false,
      Totallottery: 0,
      amountearned: 0,
      lotterylist: [],
      warn: false,
      net: false,
      isNetVer: true,
      netId: "",
      betBalance: 0,
      creatorAddress: "",
    };
  }

  async componentDidMount() {
    var lotterylist = [];
    this.setState({ loading: true });
    if (window.ethereum) {
      window.web3 = web3;
      window.web3.eth.net.getId().then((netId) => {
        if (netId == networkID) {
          this.setState({ isNetVer: true });
        } else {
          // alert("Please Connect to Polygon main net (Network Id 137)")
          this.setState({ warn: true });
          this.setState({ isNetVer: false });
          this.setState({ net: true });
        }
      });
      let account = await web3.eth.getAccounts();
      this.setState({ creatorAddress: account[0] });
      window.ethereum.on("accountsChanged", () => {
        window.location.reload();
      });
      if (account.length !== 0) {
        cinstance.methods
          .getOrglotteries(account[0])
          .call()
          .then((res) => {
            this.setState({ loading: false });
            this.setState({ Totallottery: res.length });
          })
          .catch((err) => this.setState({ loading: false }));

        usercinstance.methods
          .organisationbyaddr(account[0])
          .call()
          .then((res) => {
            this.setState({ approved: res, loading: false });
          })
          .catch((err) => this.setState({ loading: false }));
        usercinstance.methods
          .amountEarned(account[0])
          .call()
          .then((res) => {
            this.setState({
              amountearned: web3.utils.fromWei(`${res}`, "ether"),
              loading: false,
            });
          })
          .catch((err) => this.setState({ loading: false }));
        cinstance.methods
          .amountLocked(account[0])
          .call()
          .then((res) => {
            this.setState({
              amountLocked: web3.utils.fromWei(`${res}`, "ether"),
              loading: false,
            });
          })
          .catch((err) => this.setState({ loading: false }));

        // giftcardcinstance.methods
        //   .getBetTokenBalance(account[0])
        //   .call()
        //   .then((res) => {
        //     this.setState({
        //       betBalance: web3.utils.fromWei(`${res}`, "ether"),
        //       loading: false,
        //     });
        //   })
        //   .catch((err) => this.setState({ loading: false }));
      }
    } else {
      // this.setState({ loading: false })
      // alert("Connect to wallet");
    }

    // this.setState({ loading: false })
  }

  render() {
    const {
      lotterylist,
      approved,
      TotalOrganisation,
      Totallottery,
      loading,
      amountearned,
      warn,
      net,
      isNetVer,
      amountLocked,
      betBalance,
      creatorAddress,
    } = this.state;

    return (
      <>
        {warn == true && (
          <div>
            <NavBar />
            <Container>
              <div className="orgscreendiv">
                <p className="pt-5">
                  {net
                    ? `Please Connect to Main net (Network Id ${networkID})`
                    : "Please install an Ethereum-compatible browser or extension like MetaMask to use this dApp!"}{" "}
                </p>
              </div>
            </Container>
          </div>
        )}
        {isNetVer && approved && !approved.active && !loading && (
          <RegisterAsorganisation />
        )}
        {isNetVer && approved && approved.active && !loading && (
          <>
            <OrgNavBar />
            <Container className="bgImage  p-o " fluid>
              <div className="mspace">
                {approved && !approved.active && (
                  <h3 className="mt-5 text-left pl-5 ">Account Not Approved</h3>
                )}
                <Row>
                  <Col className="org-home-top">
                    <div className="mr-4">
                      <span style={{ fontWeight: "600", fontSize: "20px" }}>
                        Referral Link
                      </span>
                      <p
                        style={{
                          fontSize: "16px",
                          wordBreak: "break-all",
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        {" "}
                        <span className="ref-link">
                          {`${window?.location?.origin}/creators?ref=
                          ${creatorAddress}`}
                        </span>
                        <FaRegCopy
                          className="ml-2 copy-icon"
                          onClick={(e) => {
                            toast.success("Reference link Copied !", {
                              position: toast.POSITION.TOP_RIGHT,
                            });

                            let refUrl = `${window?.location?.origin}/creators?ref=${creatorAddress}`;
                            navigator.clipboard.writeText(refUrl);
                          }}
                        />
                      </p>
                    </div>
                    <ButtonCommon
                      className="signup-btn org-withdraw-btn"
                      label="Withdraw Comission"
                      style={{ width: "max-content", minWidth: "175px" }}
                      handleClick={async () => {
                        let account = await web3.eth.getAccounts();
                        cinstance.methods
                          .withdrawcommission()
                          .send({ from: account[0] })
                          .then((response) => {
                            window.location.reload();
                          })
                          .catch((err) => console.log("pepep", err));
                      }}
                    />
                  </Col>
                </Row>
                <Row className="mx-auto">
                  <Col lg="12">
                    <Row>
                      <Col lg={4} md={12} sm={12} xs={12} xl={4}>
                        <Card
                          border="p-3 mt-5 mb-0"
                          className="text-center pt-2 pb-2  pl-0 pr-0 overview-item "
                        >
                          {/* <a href={Path.alllottery} style={{ textDecoration: 'none' }}> */}
                          <Card.Header>
                            <p
                              className="text-dark"
                              style={{ fontWeight: "600", fontSize: "20px" }}
                            >
                              Total Lotteries
                            </p>
                          </Card.Header>
                          <Card.Body className="bookiecardbody">
                            <Card.Text>
                              <div class="card-body text-center customcardbody">
                                <p style={{ fontSize: "35px" }}>
                                  {" "}
                                  <b> {Totallottery} </b>
                                </p>
                              </div>
                            </Card.Text>
                          </Card.Body>
                          {/* </a> */}
                        </Card>
                      </Col>

                      <Col lg={4} md={12} sm={12} xs={12} xl={4}>
                        <Card
                          // bg="light"
                          border="p-3 mt-5 mb-0"
                          className="text-center pt-2 pb-2  pl-0 pr-0 overview-item"
                          // style={{ height: '18rem' }}
                        >
                          <Card.Header>
                            <p
                              class="text-dark"
                              style={{ fontWeight: "600", fontSize: "20px" }}
                            >
                              Total Amount locked
                            </p>
                          </Card.Header>
                          <Card.Body>
                            <Card.Text>
                              <div class="card-body text-center customcardbody">
                                <p style={{ fontSize: "35px" }}>
                                  {" "}
                                  <b> {amountLocked} POL</b>
                                </p>
                              </div>
                            </Card.Text>
                          </Card.Body>
                        </Card>
                      </Col>
                      <Col lg={4} md={12} sm={12} xs={12} xl={4}>
                        <Card
                          // bg="light"
                          border="p-3 mt-5 mb-0"
                          className="text-center pt-2 pb-2  pl-0 pr-0 overview-item"
                          // style={{ height: '18rem' }}
                        >
                          <Card.Header>
                            <p
                              class="text-dark"
                              style={{ fontWeight: "600", fontSize: "20px" }}
                            >
                              Total Amount Received
                            </p>
                          </Card.Header>
                          <Card.Body>
                            <Card.Text>
                              <div class="card-body text-center customcardbody">
                                <p style={{ fontSize: "35px" }}>
                                  {" "}
                                  <b> {amountearned} POL</b>
                                </p>
                              </div>
                            </Card.Text>
                          </Card.Body>
                        </Card>
                      </Col>
                      <Col lg={4} md={12} sm={12} xs={12} xl={4}>
                        <Card
                          // bg="light"
                          border="p-3 mt-5"
                          className="text-center pt-2 pb-2  pl-0 pr-0 overview-item"
                          // style={{ height: '18rem' }}
                        >
                          <Card.Header>
                            <p
                              class="text-dark"
                              style={{ fontWeight: "600", fontSize: "20px" }}
                            >
                              BET Balance
                            </p>
                          </Card.Header>
                          <Card.Body>
                            <Card.Text>
                              <div class="card-body text-center customcardbody">
                                <p style={{ fontSize: "35px" }}>
                                  {" "}
                                  <b> {betBalance} BET</b>
                                </p>
                              </div>
                            </Card.Text>
                          </Card.Body>
                        </Card>
                      </Col>
                    </Row>
                    {/* <CardDeck> */}

                    {/* </CardDeck> */}
                  </Col>
                </Row>
              </div>
            </Container>
            {/* <ToastContainer autoClose={2000} /> */}
          </>
        )}
        {!approved && (
          <div>
            <NavBar />
            <Container>
              <div className="orgscreendiv">
                <p className="pt-5">
                  {!loading &&
                    `Please Connect to Main net (Network Id ${networkID})`}
                </p>
              </div>
            </Container>
          </div>
        )}
      </>
    );
  }
}

export default OrgHome;
