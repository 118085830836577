import React, { useState, useEffect } from "react";
import {
  Container,
  Row,
  Col,
  ListGroup,
  Card,
  CardColumns,
  Button,
} from "react-bootstrap";
import cinstance from "../../Service/randomcinstance";
import usercinstance from "../../Service/usercinstance";
import web3 from "../../Service/web3";
import AdminNavBar from "../../Components/AdminNavBar";
import moment from "moment";

function AdminHome() {
  const [show, setShow] = useState(false);
  const [lotterylist, setLotteryList] = useState([]);
  const [Totallottery, setTotalLottery] = useState(0);
  const [totalTicketSold, setTotalTicketSold] = useState(0);
  const [TotalOrganisation, setTotalOrganisation] = useState(0);
  const [commissionEarned, setCommissionEarned] = useState(0);
  const [totalPartners, setTotalPartners] = useState(0);
  const [totalDraws, setTotalDraws] = useState(0);
  const [totalWins, setTotalWins] = useState(0);
  const [regFee, setRegFee] = useState(0);
  const [lotteryFee, setLotteryFee] = useState(0);
  const [winnerTax, setWinnerTax] = useState(0);
  const [payoutPartner, setPayoutPartner] = useState(0);
  const [payoutAdmin, setPayoutAdmin] = useState(0);
  const [payoutWinner, setPayoutWinner] = useState(0);

  useEffect(() => {
    async function fetchData() {
      var lotterylist = [];
      if (window.ethereum) {
        window.web3 = web3;

        const p = await cinstance.methods.admin().call();
        let account = await web3.eth.getAccounts();

        if (p === account[0]) setShow(true);

        // const response1 = await cinstance.methods.commissionEarned(p).call();
        // setCommissionEarned(response1);

        const res2 = await cinstance.methods.lotteryId().call();
        setTotalLottery(res2 - 1);

        const res3 = await usercinstance.methods.ownerId().call();
        setTotalOrganisation(res3 - 1);

        const res4 = await cinstance.methods.partnerId().call();
        setTotalPartners(res4);

        const res5 = await cinstance.methods.totalDraws().call();
        setTotalDraws(res5);

        const res6 = await cinstance.methods.totalWinners().call();
        setTotalWins(res6);

        const res7 = await cinstance.methods.totalPartnerPay().call();
        setPayoutPartner(res7);

        const res8 = await cinstance.methods.totalCommissionEarned().call();
        setPayoutAdmin(res8);

        const res9 = await usercinstance.methods.registrationFees(p).call();
        setRegFee(res9);

        const res10 = await cinstance.methods
          .totalLotteryCreationFees(p)
          .call();
        setLotteryFee(res10);

        const res11 = await cinstance.methods.winnerTax(p).call();
        setWinnerTax(res11);

        const res12 = await cinstance.methods.totalSale().call();
        setTotalTicketSold(res12);

        const res13 = await cinstance.methods.totalWinnerAmount(p).call();
        setPayoutWinner(res13);

        const calculatedCommissionEarned =
          Number(res9) + Number(res10) + Number(res11);
        setCommissionEarned(calculatedCommissionEarned);
      } else {
        alert("Connect to wallet");
      }
    }
    fetchData();
  }, []);

  return (
    <>
      <AdminNavBar />
      {!show && (
        <Container className="bgImage p-o">
          <div className="mspace mt-5">
            <h2>Not an admin</h2>
          </div>
        </Container>
      )}

      {show && (
        <Container className="bgImage p-o m-0" fluid>
          <div className="mspace">
            <div className="adspace">
              <Row className={"justify-content-between mt-3"}>
                <Col sm={6}>
                  <div className="addr">
                    Platform Earned :{" "}
                    {commissionEarned
                      ? (commissionEarned / 10 ** 18).toFixed(5)
                      : 0}{" "}
                    POL
                  </div>
                </Col>
                <Col sm={4} className="adminHomeBtn">
                  <Button
                    onClick={async () => {
                      try {
                        let account = await web3.eth.getAccounts();
                        await cinstance.methods
                          .withdrawAdmin()
                          .send({ from: account[0] });
                        await usercinstance.methods
                          .withdrawRegisterationFees()
                          .send({ from: account[0] });
                        window.location.reload();
                      } catch (error) {
                        console.error(
                          "Error occurred during withdrawal:",
                          error
                        );
                      }
                    }}
                  >
                    Withdraw
                  </Button>
                </Col>
              </Row>

              <Row className="mb-2">
                <Col xs={12} className="mt-5 mb-3">
                  <h4>Platform Earnings</h4>
                </Col>
                <Col lg={4} md={12} sm={12} xs={12} xl={4}>
                  <Card
                    border="p-3 mt-0 mb-5"
                    className="text-center pt-2 pb-2  pl-0 pr-0 overview-item"
                  >
                    <Card.Header>
                      <p
                        class="text-dark"
                        style={{ fontWeight: "600", fontSize: "20px" }}
                      >
                        Registration Fees
                      </p>
                    </Card.Header>
                    <Card.Body>
                      <Card.Text>
                        <div class="card-body text-center customcardbody">
                          <p style={{ fontSize: "35px" }}>
                            {" "}
                            <b>
                              {" "}
                              {regFee
                                ? Number(regFee / 10 ** 18).toFixed(5)
                                : 0}{" "}
                              POL
                            </b>
                          </p>
                        </div>
                      </Card.Text>
                    </Card.Body>
                  </Card>
                </Col>
                <Col lg={4} md={12} sm={12} xs={12} xl={4}>
                  <Card
                    border="p-3 mt-0 mb-5"
                    className="text-center pt-2 pb-2  pl-0 pr-0 overview-item"
                  >
                    <Card.Header>
                      <p
                        class="text-dark"
                        style={{ fontWeight: "600", fontSize: "20px" }}
                      >
                        Lottery Creation Fees
                      </p>
                    </Card.Header>
                    <Card.Body>
                      <Card.Text>
                        <div class="card-body text-center customcardbody">
                          <p style={{ fontSize: "35px" }}>
                            {" "}
                            <b>
                              {lotteryFee
                                ? Number(lotteryFee / 10 ** 18).toFixed(5)
                                : 0}{" "}
                              POL
                            </b>
                          </p>
                        </div>
                      </Card.Text>
                    </Card.Body>
                  </Card>
                </Col>
                <Col lg={4} md={12} sm={12} xs={12} xl={4}>
                  <Card
                    border="p-3 mt-0 mb-5"
                    className="text-center pt-2 pb-2  pl-0 pr-0 overview-item"
                  >
                    <Card.Header>
                      <p
                        class="text-dark"
                        style={{ fontWeight: "600", fontSize: "20px" }}
                      >
                        Winner’s Tax
                      </p>
                    </Card.Header>
                    <Card.Body>
                      <Card.Text>
                        <div class="card-body text-center customcardbody">
                          <p style={{ fontSize: "35px" }}>
                            {" "}
                            <b>
                              {" "}
                              {winnerTax
                                ? Number(winnerTax / 10 ** 18).toFixed(5)
                                : 0}{" "}
                              POL
                            </b>
                          </p>
                        </div>
                      </Card.Text>
                    </Card.Body>
                  </Card>
                </Col>
              </Row>

              <Row className="mb-2">
                <Col xs={12} className="mb-3">
                  <h4>Platform Activity</h4>
                </Col>
                <Col lg={4} md={12} sm={12} xs={12} xl={4}>
                  <Card
                    border="p-3 mt-0 mb-5"
                    className="text-center pt-2 pb-2  pl-0 pr-0 overview-item"
                  >
                    <Card.Header>
                      <p
                        class="text-dark"
                        style={{ fontWeight: "600", fontSize: "20px" }}
                      >
                        Total Creators
                      </p>
                    </Card.Header>
                    <Card.Body>
                      <Card.Text>
                        <div class="card-body text-center customcardbody">
                          <p style={{ fontSize: "35px" }}>
                            {" "}
                            <b> {TotalOrganisation}</b>
                          </p>
                        </div>
                      </Card.Text>
                    </Card.Body>
                  </Card>
                </Col>
                <Col lg={4} md={12} sm={12} xs={12} xl={4}>
                  <Card
                    border="p-3 mt-0 mb-5"
                    className="text-center pt-2 pb-2  pl-0 pr-0 overview-item"
                  >
                    <Card.Header>
                      <p
                        class="text-dark"
                        style={{ fontWeight: "600", fontSize: "20px" }}
                      >
                        Total Lotteries
                      </p>
                    </Card.Header>
                    <Card.Body>
                      <Card.Text>
                        <div class="card-body text-center customcardbody">
                          <p style={{ fontSize: "35px" }}>
                            {" "}
                            <b> {Totallottery}</b>
                          </p>
                        </div>
                      </Card.Text>
                    </Card.Body>
                  </Card>
                </Col>
                <Col lg={4} md={12} sm={12} xs={12} xl={4}>
                  <Card
                    border="p-3 mt-0 mb-5"
                    className="text-center pt-2 pb-2  pl-0 pr-0 overview-item"
                  >
                    <Card.Header>
                      <p
                        class="text-dark"
                        style={{ fontWeight: "600", fontSize: "20px" }}
                      >
                        Total Ticket Sold
                      </p>
                    </Card.Header>
                    <Card.Body>
                      <Card.Text>
                        <div class="card-body text-center customcardbody">
                          <p style={{ fontSize: "35px" }}>
                            {" "}
                            <b> {totalTicketSold}</b>
                          </p>
                        </div>
                      </Card.Text>
                    </Card.Body>
                  </Card>
                </Col>
                <Col lg={4} md={12} sm={12} xs={12} xl={4}>
                  <Card
                    border="p-3 mt-0 mb-5"
                    className="text-center pt-2 pb-2  pl-0 pr-0 overview-item"
                  >
                    <Card.Header>
                      <p
                        class="text-dark"
                        style={{ fontWeight: "600", fontSize: "20px" }}
                      >
                        Total Draws
                      </p>
                    </Card.Header>
                    <Card.Body>
                      <Card.Text>
                        <div class="card-body text-center customcardbody">
                          <p style={{ fontSize: "35px" }}>
                            {" "}
                            <b> {totalDraws}</b>
                          </p>
                        </div>
                      </Card.Text>
                    </Card.Body>
                  </Card>
                </Col>
                <Col lg={4} md={12} sm={12} xs={12} xl={4}>
                  <Card
                    border="p-3 mt-0 mb-5"
                    className="text-center pt-2 pb-2  pl-0 pr-0 overview-item"
                  >
                    <Card.Header>
                      <p
                        class="text-dark"
                        style={{ fontWeight: "600", fontSize: "20px" }}
                      >
                        Total Wins
                      </p>
                    </Card.Header>
                    <Card.Body>
                      <Card.Text>
                        <div class="card-body text-center customcardbody">
                          <p style={{ fontSize: "35px" }}>
                            {" "}
                            <b> {totalWins}</b>
                          </p>
                        </div>
                      </Card.Text>
                    </Card.Body>
                  </Card>
                </Col>
                <Col lg={4} md={12} sm={12} xs={12} xl={4}>
                  <Card
                    border="p-3 mt-0 mb-5"
                    className="text-center pt-2 pb-2  pl-0 pr-0 overview-item"
                  >
                    <Card.Header>
                      <p
                        class="text-dark"
                        style={{ fontWeight: "600", fontSize: "20px" }}
                      >
                        Total Partners
                      </p>
                    </Card.Header>
                    <Card.Body>
                      <Card.Text>
                        <div class="card-body text-center customcardbody">
                          <p style={{ fontSize: "35px" }}>
                            {" "}
                            <b> {totalPartners}</b>
                          </p>
                        </div>
                      </Card.Text>
                    </Card.Body>
                  </Card>
                </Col>
              </Row>
              <Row className="mb-5">
                <Col xs={12} className="mb-3">
                  <h4>Payout</h4>
                </Col>
                <Col lg={4} md={12} sm={12} xs={12} xl={4}>
                  <Card
                    border="p-3 mt-0 mb-5"
                    className="text-center pt-2 pb-2  pl-0 pr-0 overview-item"
                  >
                    <Card.Header>
                      <p
                        class="text-dark"
                        style={{ fontWeight: "600", fontSize: "20px" }}
                      >
                        Winner’s
                      </p>
                    </Card.Header>
                    <Card.Body>
                      <Card.Text>
                        <div class="card-body text-center customcardbody">
                          <p style={{ fontSize: "35px" }}>
                            {" "}
                            <b>
                              {" "}
                              {payoutWinner
                                ? Number(payoutWinner / 10 ** 18).toFixed(5)
                                : 0}{" "}
                              POL
                            </b>
                          </p>
                        </div>
                      </Card.Text>
                    </Card.Body>
                  </Card>
                </Col>
                <Col lg={4} md={12} sm={12} xs={12} xl={4}>
                  <Card
                    border="p-3 mt-0 mb-5"
                    className="text-center pt-2 pb-2  pl-0 pr-0 overview-item"
                  >
                    <Card.Header>
                      <p
                        class="text-dark"
                        style={{ fontWeight: "600", fontSize: "20px" }}
                      >
                        Partners
                      </p>
                    </Card.Header>
                    <Card.Body>
                      <Card.Text>
                        <div class="card-body text-center customcardbody">
                          <p style={{ fontSize: "35px" }}>
                            {" "}
                            <b>
                              {" "}
                              {payoutPartner
                                ? Number(payoutPartner / 10 ** 18).toFixed(5)
                                : 0}{" "}
                              POL
                            </b>
                          </p>
                        </div>
                      </Card.Text>
                    </Card.Body>
                  </Card>
                </Col>
                <Col lg={4} md={12} sm={12} xs={12} xl={4}>
                  <Card
                    border="p-3 mt-0 mb-5"
                    className="text-center pt-2 pb-2  pl-0 pr-0 overview-item"
                  >
                    <Card.Header>
                      <p
                        class="text-dark"
                        style={{ fontWeight: "600", fontSize: "20px" }}
                      >
                        Admin
                      </p>
                    </Card.Header>
                    <Card.Body>
                      <Card.Text>
                        <div class="card-body text-center customcardbody">
                          <p style={{ fontSize: "35px" }}>
                            {" "}
                            <b>
                              {" "}
                              {commissionEarned
                                ? Number(commissionEarned / 10 ** 18).toFixed(5)
                                : 0}{" "}
                              POL
                            </b>
                          </p>
                        </div>
                      </Card.Text>
                    </Card.Body>
                  </Card>
                </Col>
              </Row>
            </div>
          </div>
        </Container>
      )}
    </>
  );
}

export default AdminHome;
