import React, { useState, useEffect } from "react";
import { Container, Col, Row, Card, Image } from "react-bootstrap";
import axios from "axios";
import { useHistory } from "react-router-dom";
import Marquee from "react-fast-marquee";
import web3 from "../Service/web3";
import "./counter.css";
import Fade from "react-reveal/Fade";
import Slide from "react-reveal/Slide";
const Banner = ({ item, bookies, lotteries, buytotal, draws, wins }) => {
  const [featuredNews, setFeaturedNews] = useState([]);
  const [notifications, setNotifications] = useState([]);
  const [winnerNotifications, setWinnerNotifications] = useState([]);
  const apiUrl = process.env.REACT_APP_API_KEY;
  const history = useHistory();

  useEffect(() => {
    axios.get(`${apiUrl}/news/getAllNews`).then((response) => {
      const newsData = response.data.result;
      const featuredNewsData = newsData.filter((news) => news.featured);
      setFeaturedNews(featuredNewsData);
    });

    const fetchData = async () => {
      try {
        let account = await web3.eth.getAccounts();
        let skip = 0;
        let allNotifications = [];
        let allWinnerNotifications = [];

        const fetchNotifications = async () => {
          const response = await fetch(
            `${apiUrl}/notifications?limit=10&skip=${skip}`,
            {
              method: "GET",
              headers: {
                Authorization: account[0],
              },
            }
          );
          if (response.ok) {
            const data = await response.json();
            const filteredNotifications = data.result.filter(
              (notification) =>
                notification.type === "LotteryCreatedAdmin" ||
                notification.type === "LotteryCreated"
            );
            if (filteredNotifications.length > 0) {
              allNotifications = [
                ...allNotifications,
                ...filteredNotifications,
              ];
              skip += 10;
              await fetchNotifications();
            } else {
              setNotifications(allNotifications);
            }

            const filteredWinnerNotifications = data.result.filter(
              (notification) =>
                notification.type === "LotteryResult" ||
                notification.type === "SpinLotteryResult" ||
                notification.type === "LotteryWinner"
            );
            if (filteredWinnerNotifications.length > 0) {
              allWinnerNotifications = [
                ...allWinnerNotifications,
                ...filteredWinnerNotifications,
              ];
              skip += 10;
              await fetchNotifications();
            } else {
              setWinnerNotifications(allWinnerNotifications);
            }
          } else {
            console.error("Error fetching notifications:", response.error);
          }
        };
        await fetchNotifications();
      } catch (error) {
        console.error("Error fetching notifications:", error);
      }
    };
    fetchData();
  }, []);

  function handleRedirect(notification) {
    switch (notification.type) {
      case "LotteryCreated":
        if (notification.lotteryName === "Missile") {
          history.push(`/missilelottery/${notification.genId}`);
        } else if (notification.lotteryName === "Mine") {
          history.push(`/minelottery/${notification.genId}`);
        } else if (notification.lotteryName === "MRL") {
          history.push(`/lottery/${notification.genId}`);
        } else {
          history.push("/spinnerlottery");
        }
        break;

      case "LotteryCreatedAdmin":
        if (notification.lotteryName === "Missile") {
          history.push(`/missilelottery/${notification.genId}`);
        } else if (notification.lotteryName === "Mine") {
          history.push(`/minelottery/${notification.genId}`);
        } else if (notification.lotteryName === "MRL") {
          history.push(`/lottery/${notification.genId}`);
        } else {
          history.push("/spinnerlottery");
        }
        break;

      default:
        break;
    }
  }

  return (
    <Container fluid className="mb-4">
      {((featuredNews !== undefined && featuredNews.length > 0) ||
        (notifications !== undefined && notifications.length > 0)) && (
        <Row>
          <Col className="m-0 mt-3 p-0">
            <Marquee pauseOnHover={true} gradient={true} className="marq">
              {featuredNews.map((news) => (
                <span key={news.id} className="feature-msg mx-4">
                  <a href={`/news/${news.id}`}>{news.title}</a>
                </span>
              ))}
              {notifications.slice(0, 3).map((item) => (
                <span
                  key={item.id}
                  onClick={() => handleRedirect(item)}
                  className="feature-msg mx-4"
                >
                  ID-{item.genId}: "{item.title}"
                </span>
              ))}
              {winnerNotifications.slice(0, 3).map((item) => (
                <span
                  key={item.id}
                  onClick={() => history.push("/lottery-result")}
                  className="feature-msg mx-4"
                >
                  ID-{item.genId}: "{item.title}"
                </span>
              ))}
            </Marquee>
          </Col>
        </Row>
      )}
      <section className="banner-section">
        <div className="banner-content-area">
          <div className="container container-p0">
            <Fade left>
              <div className="row">
                <div className="col-md-6 col-lg-8">
                  <div className="banner-content">
                    <h1
                      className="title"
                      style={{ textAlign: "left", fontWeight: "600" }}
                    >
                      Join the <span className="red-text-color">AutoBET</span>{" "}
                      Revolution in Fundraising over Decentralized Lottery
                      Ecosystem
                    </h1>
                    <p>
                      AutoBET is an innovative web3 fundraising platform with
                      prewhite-labelled lottery products and Gift Card
                      donation system. It is fully automated on smart-contracts
                      that run on the Ethereum Network. Currently, only POL is
                      accepted as payment and plans are in place to integrate
                      its native BET token as well as other major digital
                      currencies.
                    </p>
                    {/* <a href="#0" className="cmn-btn">buy ticket now !</a> */}
                  </div>
                </div>
              </div>
            </Fade>
            <div className="counter-wrapper">
              <div className="row ">
                <div className="col-lg-2 col-4 ">
                  <Fade bottom delay={100}>
                    <div className="single-counter counter-color-5 d-flex align-items-center justify-content-center">
                      <div className="counter-items text-center">
                        <span className="count">{bookies}</span>
                        <p className="text">Creators</p>
                      </div>
                    </div>
                  </Fade>
                </div>
                <div className="col-lg-2 col-4">
                  <Fade bottom delay={200}>
                    <div className="single-counter counter-color-4 d-flex align-items-center justify-content-center">
                      <div className="counter-items text-center">
                        <span className="count">{lotteries}</span>
                        <p className="text">Lotteries</p>
                      </div>
                    </div>
                  </Fade>
                </div>
                <div className="col-lg-2 col-4">
                  <Fade bottom delay={300}>
                    <div className="single-counter counter-color-3 d-flex align-items-center justify-content-center">
                      <div className="counter-items text-center">
                        <span className="count">{buytotal}</span>
                        <p className="text">Tickets sold</p>
                      </div>
                    </div>
                  </Fade>
                </div>
                <div className="col-lg-2 col-4">
                  <Fade bottom delay={400}>
                    <div className="single-counter counter-color-2 d-flex align-items-center justify-content-center">
                      <div className="counter-items text-center">
                        <span className="count">{draws}</span>
                        <p className="text">Draws</p>
                      </div>
                    </div>
                  </Fade>
                </div>
                <div className="col-lg-2 col-4">
                  <Fade bottom delay={500}>
                    <div className="single-counter counter-color-1 d-flex align-items-center justify-content-center">
                      <div className="counter-items text-center">
                        <span className="count">{wins}</span>
                        <p className="text">Wins</p>
                      </div>
                    </div>
                  </Fade>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Slide right>
          <div className="bg cubeimage ">
            <img
              src={require("../../static/Images/Banner img 1.svg").default}
              className="cubeimage"
            ></img>
          </div>
        </Slide>
      </section>
    </Container>
  );
};
export default Banner;
