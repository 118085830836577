import React from "react";
import { Container, Col, Row, Card, Button } from "react-bootstrap";
import moment from "moment";
import getImageUrlByHash from "../../../static/logoUtils";
import AutoLogo from "../../../static/Images/logo.png";
import web3 from "../../Service/web3";
import "./MineCard.css";

const MineCard = ({ item, history, lotteryType, partner, partnerLogo }) => {
  const imageUrl = getImageUrlByHash(partner?.logoHash, partnerLogo);

  return (
    <Card className="lottery-card">
      {/* <div className="outercircle">
        <div className="circle1">
          {" "}
          <span className="coinnumber1">{item.capacity}</span>
        </div>
        <div className="circle2">
          <span className="coinnumber">{item.pickNumbers}</span>
        </div>
      </div> */}
      {lotteryType === "missile" && item.status === "3" && (
        <div className="lottery-tag">
          <span>Rollover</span>
        </div>
      )}

      <Card.Body>
        <Card.Title
          className="whitetext"
          //  style={{ color: textcolor }}
        >
          {moment.unix(item && item.deployedOn).format("DDMMYYYY")}-{""}
          {item.lotteryId}
        </Card.Title>
        <p>Chance to win</p>
        <Card.Title className={"amount"}>
          {web3.utils.fromWei(`${item.totalPrize}`, "ether")}POL
        </Card.Title>

        {(partner?.logoHash !== undefined || partner?.logoHash !== "") && (
          <img src={imageUrl} className="partnerlogo" />
        )}

        {/* <Card.Subtitle
          className="mt-2 mb-2 whitetext2"
          //style={{ color: textcolor }}
        >
          Ends on {moment.unix(item.endTime).format("DD/MM/YYYY hh:mma")}
        </Card.Subtitle> */}
      </Card.Body>
      {lotteryType === "missile" ? (
        <a
          onClick={() => history.push(`/missilelottery/${item.lotteryId}`)}
          className="mybtn1 lotterycardbtn"
          style={{ textDecoration: "none", border: "2px solid #0f8afd" }}
        >
          Play Now!
        </a>
      ) : (
        <a
          onClick={() => history.push(`/minelottery/${item.lotteryId}`)}
          className="mybtn1 lotterycardbtn"
          style={{ textDecoration: "none", border: "2px solid #0f8afd" }}
        >
          Play Now!
        </a>
      )}
    </Card>
  );
};
export default MineCard;
