import React, { useState, useEffect } from "react";
import { Card, Button } from "react-bootstrap";
import web3 from "../../Service/web3";
import getImageUrlByHash from "../../../static/logoUtils";
import giftcardcinstance from "../../Service/giftcardcinstance";
import ShareIcon from "../../../static/Images/share.png";
import AutobetLogo from "../../../static/Images/logo.png";
import moment from "moment";
import { toast, ToastContainer } from "react-toastify";
import "./MyGiftCardStyle.css";

const MyGiftCard = ({ item, history, userRole, isExpired, giftcardLogo }) => {
  // console.log("dataaaaaa", item);
  const imageUrl = getImageUrlByHash(item.logoHash, giftcardLogo);

  return (
    <Card className="list-giftcard">
      <div className="card-img-block">
        {item.logoHash !== undefined && item.logoHash !== "" ? (
          <img src={imageUrl} className="img-fluid" />
        ) : (
          <img src={AutobetLogo} className="img-fluid" />
        )}
      </div>
      <div className="giftcard-tag">
        {item.isDonation === true ? <span>Donation</span> : <span>Sell</span>}
      </div>
      <Card.Body>
        <div className="details">
          <img
            src={ShareIcon}
            className="shareicon"
            onClick={(e) => {
              toast.success("Sharable link Copied !", {
                position: toast.POSITION.TOP_CENTER,
              });

              let sharedUrl = `${window?.location?.origin}/giftcard/${item.giftCardId}`;

              if (item.voucherCode !== "") {
                sharedUrl += `?voucherCode=${item.voucherCode}`;
              }

              navigator.clipboard.writeText(sharedUrl);
            }}
          />

          <p>
            <b>Amount ( {item.tokenName}) : </b> &nbsp;
            {web3.utils.fromWei(`${item.BETValue}`, "ether")}
          </p>
          <p>
            <b>Price (POL) : </b> &nbsp;
            {web3.utils.fromWei(`${item.ETHPrice}`, "ether")}
          </p>
          <p>
            <b>Expiry : </b> &nbsp;
            {moment.unix(item && item.expDate).format("DD-MM-YYYY HH:mm")}
          </p>
          {item.voucherCode !== "" && (
            <p>
              <b>Voucher Code : </b> &nbsp;
              {item.voucherCode}
            </p>
          )}

          <p>
            <b>Creator : </b> &nbsp;
            <span style={{ wordBreak: "break-all" }}>{item.creator}</span>
          </p>
          <p>
            <b>Description : </b> &nbsp;
            <span className="card-desc">{item.Desc}</span>
          </p>
        </div>
        <div className="action">
          <div
            className="pt-2"
            onClick={() => {
              if (userRole === "admin") {
                history.push(`/admin/giftcarddetails/${item.giftCardId}`);
              } else {
                history.push(`/creator/giftcarddetails/${item.giftCardId}`);
              }
            }}
          >
            {" "}
            <p className="underlinelink">View Details</p>
          </div>
          {isExpired && item.status === "0" && (
            <div className="transfer-button">
              <Button
                onClick={async () => {
                  try {
                    let account = await web3.eth.getAccounts();
                    await giftcardcinstance.methods
                      .withdrawCreatorTokens(item.giftCardId)
                      .send({ from: account[0] });
                    window.location.reload();
                  } catch (error) {
                    console.error("Error occurred during transfer:", error);
                  }
                }}
              >
                Transfer Token
              </Button>
            </div>
          )}
        </div>
        <ToastContainer autoClose={2000} />
      </Card.Body>
    </Card>
  );
};
export default MyGiftCard;
