import React, { Component } from "react";
import { Container, Row, Col, Table } from "react-bootstrap";
import cinstance from "../../Service/randomcinstance";
import web3 from "../../Service/web3";
import NavBar from "../../Components/NavBar";
import moment from "moment";
import Loaders from "../../Components/Loaders";

class LotteryDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      seed: "",
      loading: true,
      Randomresult: "",
      winner: "",
      lotterylist: [],
      datelist: [],
      lotterywinner: [],
      Lotteriestypes: "Open Lotteries",
      showdraw: true,
    };
  }

  async componentDidMount() {
    var lotterylist = [];
    var datelist = [];
    if (window.ethereum) {
      window.web3 = web3;
      cinstance.methods
        .lottery(this.props.match.params.id)
        .call()
        .then((response) => {
          if (response) {
            cinstance.getPastEvents(
              "LotteryResult",
              {
                filter: { lotteryId: `${this.props.match.params.id}` },
                fromBlock: 0,
                toBlock: "latest",
              },
              (errors, events) => {
                if (!errors) {
                  this.setState({ WinnersId: events });
                }
              }
            );
          }
          cinstance.methods
            .getLotteryNumbers(this.props.match.params.id)
            .call()
            .then((res) => {
              if (response.lotteryType != "3") {
                let sizes = response.pickNumbers;
                let ticketbought = res.tickets;
                let useraddress = res.useraddress;
                useraddress.forEach((i, index) => {
                  lotterylist.push({
                    useraddress: i,
                    ticketbought: ticketbought?.slice(
                      index * sizes,
                      (index + 1) * sizes
                    ),
                  });
                });
              } else {
                res.useraddress.forEach((i, index) => {
                  lotterylist.push({
                    useraddress: i,
                    ticketbought: res.tickets[index],
                  });
                });
              }
              this.setState({ lotterylist });
            });
          this.setState({ lottery: response, loading: false });
          cinstance.methods
            .lotteryDates(this.props.match.params.id)
            .call()
            .then((responsee) => {
              this.setState({ datelist: responsee });
            })
            .catch((err) => console.log("error", err));
        })
        .catch((err) => console.log("pepep", err));

      let account = await web3.eth.getAccounts();
    } else {
      alert("Connect to wallet");
    }
  }
  getDraw = async () => {
    if (window.ethereum) {
      window.web3 = web3;
      this.setState({ loading: true });
      let account = await web3.eth.getAccounts();
      cinstance.methods
        .getWinnerNumbers("202020", this.props.match.params.id)
        .send({ from: account[0] })
        .then((response) => {
          this.setState({ showdraw: false });
        })
        .catch((err) => this.setState({ loading: false }));
    } else {
      alert("Connect to wallet");
    }
  };
  render() {
    const { lottery, lotterylist, datelist, loading, showdraw, WinnersId } =
      this.state;

    const formatRolloverTime = (seconds) => {
      const days = Math.floor(seconds / (60 * 60 * 24));
      const hours = Math.floor((seconds % (60 * 60 * 24)) / (60 * 60));
      const minutes = Math.floor((seconds % (60 * 60)) / 60);

      let formattedTime = "";

      if (days > 0) {
        formattedTime += `${days} day${days > 1 ? "s" : ""} `;
      }
      if (hours > 0) {
        formattedTime += `${hours} hour${hours > 1 ? "s" : ""} `;
      }
      if (minutes > 0) {
        formattedTime += `${minutes} minute${minutes > 1 ? "s" : ""} `;
      }
      return formattedTime.trim();
    };

    return (
      <div className="lottery-details">
        {loading && <Loaders />}
        <NavBar />
        <Container className="bgImage mt-2">
          <Row>
            <Col lg="12">
              <h5 style={{ textAlign: "center", marginTop: 20 }}>
                Lottery Details
              </h5>
            </Col>
          </Row>
          <Row className=" mt-5 mb-2 mx-auto">
            <Col lg="6">
              <p className="mt-1 mb-1 address-text">
                Created By : {lottery && lottery.ownerAddress}
              </p>
              <p className="mt-1 mb-1 ">
                Id :{" "}
                {moment.unix(lottery && lottery.deployedOn).format("DDMMYYYY")}{" "}
                - {lottery && lottery.lotteryId}
              </p>
              {lottery?.lotteryType === "2" && (
                <p className="mt-1 mb-1 ">
                  Minimum Players: {lottery && lottery.minPlayers}
                </p>
              )}
              {lottery?.lotteryType !== "3" && (
                <p className="mt-1 mb-1 ">
                  Total Numbers : {lottery && lottery.capacity}
                </p>
              )}
              <p className="mt-1 mb-1 ">
                Total Numbers to Pick: {lottery && lottery.pickNumbers}
              </p>
              {lottery && lottery.entryFee && (
                <p className="mt-1 mb-1 ">
                  Entry Fee:{" "}
                  {web3.utils.fromWei(`${lottery.entryFee}`, "ether")}POL
                </p>
              )}
              {lottery?.lotteryType !== "1" && (
                <p className="mt-1 mb-1 ">
                  Rollover: {lottery?.rolloverperct}%
                </p>
              )}

              {lottery?.partnershare && lottery?.lotteryType === "3" && (
                <p className="mt-1 mb-1 ">
                  Partners Share: {lottery?.partnershare}%
                </p>
              )}
            </Col>
            <Col lg="6">
              <p className="mt-1 mb-1 ">
                Lottery Type:{" "}
                {lottery && lottery.lotteryType == 0
                  ? "Autobet Revolver"
                  : lottery && lottery.lotteryType == 1
                  ? "Autobet Mine"
                  : lottery && lottery.lotteryType == 2
                  ? "Autobet MRL"
                  : "Autobet Missile"}
              </p>
              <p className="mt-1 mb-1 ">
                Start Date:{" "}
                {moment
                  .unix(datelist && datelist.startTime)
                  .format("DD/MM/YYYY hh:mma")}
              </p>

              {(lottery?.lotteryType == "2" || lottery?.lotteryType == "3") && (
                <p className="mt-1 mb-1 ">
                  Rollover Date:{" "}
                  {moment
                    .unix(datelist && datelist.drawTime)
                    .format("DD/MM/YYYY hh:mma")}
                </p>
              )}
              {/* <p className="mt-1 mb-1 ">
                  Draw Date:{" "}
                  {moment
                    .unix(datelist && datelist.drawTime)
                    .format("DD/MM/YYYY hh:mma")}
                </p> */}
              <p className="mt-1 mb-1 ">
                Status:{" "}
                {lottery && lottery.status == 0
                  ? "Open"
                  : lottery && lottery.status == 1
                  ? "Close"
                  : lottery && lottery.status == 4
                  ? "Rollover Needed"
                  : lottery && lottery.status == 5
                  ? "Rollover Done"
                  : lottery && lottery.status == 6
                  ? "Creating in progress"
                  : "Result Done"}
              </p>
              {lottery && lottery.totalPrize && (
                <p className="mt-1 mb-1 ">
                  Winner Amount:{" "}
                  {web3.utils.fromWei(`${lottery.totalPrize}`, "ether")}POL
                </p>
              )}
              {lottery?.partnershare && lottery?.lotteryType !== "3" && (
                <p className="mt-1 mb-1 ">
                  Partners Share: {lottery?.partnershare}%
                </p>
              )}
              {(lottery?.lotteryType === "2" ||
                lottery?.lotteryType === "3") && (
                <p className="mt-1 mb-1 ">
                  Rollover Duration:{" "}
                  {formatRolloverTime(datelist?.rolloverdays)}
                </p>
              )}
              {(lottery?.lotteryType === "1" ||
                lottery?.lotteryType === "2") && (
                <p className="mt-1 mb-1">
                  Winner Number:{" "}
                  {WinnersId?.length === 0
                    ? "In progress"
                    : WinnersId?.[0]?.returnValues?.n ||
                      WinnersId?.[0]?.returnValues?.number
                        ?.split("-")
                        .slice(0, -1)
                        .reverse()
                        .join("-")}
                </p>
              )}
            </Col>
          </Row>
          <Row className="mx-auto mb-3 justify-content-center">
            <Col lg="10">
              {
                lottery &&
                lottery.lotteryWinner !==
                  "0x0000000000000000000000000000000000000000" ? (
                  <p className="winner-address">
                    Winner: {lottery.lotteryWinner}
                  </p>
                ) : (
                  <p className="winner-address">Winner: No Winner</p>
                )
                /* {&&lotterywinner.map((i, index) => (
                    <p key={index + 500} className="numbercircle">
                      {i}
                    </p> 
                  ))}*/
              }
              {/* {lottery && showdraw&&
                lottery.status == 0 &&
                lottery.drawtime < moment().unix() && (
                  <div>
                  <ButtonCommon
                    label="Get Draw"
                    handleClick={this.getDraw}
                  ></ButtonCommon>
                  </div>
                )}{" "} */}
            </Col>
          </Row>
          {showdraw && <Row className="ml-4 mb-3 "></Row>}
          <Row>
            <Col lg="12">
              <Table bordered responsive>
                <thead>
                  <tr>
                    <th>Bought By</th>
                    <th>Numbers</th>
                  </tr>
                </thead>
                <tbody>
                  {lotterylist && lotterylist.length == 0 && (
                    <tr>
                      <td colspan={12} style={{ textAlign: "center" }}>
                        {" "}
                        <h4> No Data !</h4>{" "}
                      </td>
                    </tr>
                  )}
                  {lottery?.lotteryType !== "3" ? (
                    <>
                      {lotterylist &&
                        lotterylist.map((item) => (
                          <tr key={item.lotteryId}>
                            <td className="address-text">{item.useraddress}</td>
                            <td className="row m-0 numbercirclediv">
                              {" "}
                              {item.ticketbought.map((i) => (
                                <p className="numbercircle">{i}</p>
                              ))}
                            </td>
                          </tr>
                        ))}
                    </>
                  ) : (
                    <>
                      {lotterylist &&
                        lotterylist.map((item) => {
                          const ticketDigits = item.ticketbought
                            .toString()
                            .split("")
                            .map(Number);
                          return (
                            <tr key={item.lotteryId}>
                              <td className="address-text">
                                {item.useraddress}
                              </td>

                              <td className="row m-0 numbercirclediv">
                                {ticketDigits.map((digit, index) => (
                                  <p key={index} className="numbercircle">
                                    {digit}
                                  </p>
                                ))}
                              </td>
                            </tr>
                          );
                        })}
                    </>
                  )}
                </tbody>
              </Table>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}

export default LotteryDetails;
