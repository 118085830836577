import React, { useState, useEffect } from "react";
import { Container, Col, Row, Dropdown, Button, Card } from "react-bootstrap";
import cinstance from "../../Service/randomcinstance";
import web3 from "../../Service/web3";
import axios from "axios";
import getImageUrlByHash from "../../../static/logoUtils";
import moment from "moment";
import { Input } from "reactstrap";
import NavBar from "../../Components/NavBar";
import AutoLogo from "../../../static/Images/logo.png";
import Missile1 from "../../../static/Images/missile-icon.svg";
import Hypersonic from "../../../static/Images/Hypersonic.png";
import Ballastic from "../../../static/Images/Ballastic.png";
import Cruis from "../../../static/Images/cruis.png";
import Initiation from "../../../static/Images/Initiation.png";
import StarBg from "../../../static/Images/Stars.svg";
import StarBg1 from "../../../static/Images/Stars1.png";
import SuccessPopup from "../SuccessPopup";
import FailedPopup from "../FailedPopup";
import Loaders from "../Loaders";
import Warnings from "../Warning";
import { Path } from "../../../static/Constants";
import "./MissileStyle.css";

const Missile = (props) => {
  const [showFail, setShowFail] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [msg, setmsg] = useState("");
  const [selectNumber, setSelectNumber] = useState("");
  const [isEnterClicked, setIsEnterClicked] = useState(false);
  const [lotterylist, setLotteryList] = useState({
    entryFee: 0,
    totalPrize: 0,
  });
  const [datelist, setDateList] = useState({});
  const [partnerList, setPartnerList] = useState({});
  const [MissileType, setMissileType] = useState("initiation");
  const [partnerLogoData, setPartnerLogoData] = useState([]);
  const apiUrl = process.env.REACT_APP_API_KEY;

  const MissileList = [
    {
      value: "initiation",
      label: "Initiation",
      id: 4,
    },
    {
      value: "cruis",
      label: "Cruis Missile",
      id: 3,
    },
    {
      value: "ballastic",
      label: "Ballastic Missile",
      id: 2,
    },
    {
      value: "hypersonic",
      label: "Hypersonic Missile",
      id: 1,
    },
  ];
  const buy = async () => {
    if (window.ethereum) {
      window.web3 = web3;
      setLoading(true);
      let account = await web3.eth.getAccounts();

      if (account.length != 0)
        cinstance.methods
          .buyMissilelottery(selectNumber, props.match.params.id)
          .send({
            from: account[0],
            value: lotterylist.entryFee,
          })
          .then((res) => {
            cinstance.methods
              .lottery(props.match.params.id)
              .call()
              .then((response) => {
                if (response.lotteryWinner == account[0]) {
                  setmsg(
                    "Congratulations! You're the lucky winner of the lottery!"
                  );
                } else {
                  setmsg("You lose. Better luck next time!");
                }
                setShowSuccess(true);

                setLoading(false);
              });
          })
          .catch((er) => {
            setLoading(false);
            setShowFail(true);
          });
      else {
        window.ethereum.enable();
      }
    }
  };
  useEffect(() => {
    const fetchData = async () => {
      if (window.ethereum) {
        window.web3 = web3;

        let account = await web3.eth.getAccounts();
        if (account && account.length !== 0) {
          cinstance.methods
            .lottery(props.match.params.id)
            .call()
            .then((response) => {
              setLotteryList(response);
              cinstance.methods
                .lotteryDates(props.match.params.id)
                .call()
                .then((responsee) => {
                  setDateList(responsee);
                  setMissileType(MissileList[responsee?.level - 1]?.value);
                })
                .catch((err) => console.log("error", err));
              cinstance.methods
                .partnerbyaddr(response.partnerAddress)
                .call()
                .then((responsee) => {
                  setPartnerList(responsee);
                })
                .catch((err) => console.log("error", err));
            })
            .catch((err) => console.log("pepep", err));
        } else {
          setError(true);
        }
      } else {
        setError(true);
      }
      setLoading(false);
    };
    fetchData();
  }, [props.match.params.id]);

  const buttonValues = [
    [1, 2, 3],
    [4, 5, 6],
    [7, 8, 9],
    ["Delete", 0, "Enter"],
  ];

  function handleButtonClick(value) {
    if (value === "Delete") {
      if (selectNumber.toString().length > 0) {
        setSelectNumber(selectNumber?.slice(0, -1));
      } else {
        setSelectNumber("");
      }
    } else if (value === "Enter") {
      buy();
    } else if (selectNumber.toString().length < lotterylist.pickNumbers) {
      setSelectNumber(selectNumber + value);
    }
  }

  const numSelected = selectNumber.length;
  const maxSelected = lotterylist.pickNumbers;
  const isDisabled = numSelected >= maxSelected;

  useEffect(() => {
    axios.get(`${apiUrl}/s3imageupload/getAllPartnerLogos`).then((response) => {
      const partnerData = response.data.result;
      setPartnerLogoData(partnerData);
    });
  }, []);

  const imageUrl = getImageUrlByHash(partnerList.logoHash, partnerLogoData);

  return (
    <div className="Missile">
      {loading && <Loaders />}
      {error && <Warnings />}
      <NavBar />
      <div
        className={
          MissileType !== "hypersonic"
            ? "outer-container"
            : "outer-container bgcolor"
        }
      >
        {MissileType === "hypersonic" ? (
          <img src={StarBg} className="bgImage1" />
        ) : (
          <img src={StarBg1} className="bgImage1" />
        )}
        <Container>
          {/* <Row>
            <Col xs={12}>
              <Dropdown className="dp-class">
                <Dropdown.Toggle id="dropdown-basic">
                  <span>{MissileType}</span>
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  {MissileList.map((item) => (
                    <Dropdown.Item
                      onClick={() => {
                        setMissileType(item.value);
                      }}
                    >
                      {item.label}
                    </Dropdown.Item>
                  ))}
                </Dropdown.Menu>
              </Dropdown>
            </Col>
          </Row> */}
          <Row className="missile-col">
            <Col lg={6} className="missile-col order-lg-2">
              {MissileType === "hypersonic" && (
                <div className="missile-type1">
                  <span>Hypersonic Missile</span>
                  <div className="missile-img">
                    <img src={Hypersonic} className="hypersonic" />
                  </div>
                </div>
              )}
              {MissileType === "ballastic" && (
                <div className="missile-type">
                  <span>Ballastic Missile</span>
                  <div className="missile-img">
                    <img src={Ballastic} className="ballastic" />
                  </div>
                </div>
              )}
              {MissileType === "cruis" && (
                <div className="missile-type">
                  <span>Cruise Missile</span>
                  <div className="missile-img">
                    <img src={Cruis} className="cruis" />
                  </div>
                </div>
              )}
              {MissileType === "initiation" && (
                <div className="missile-type">
                  <span>Initiation</span>
                  <div className="missile-img">
                    <img src={Initiation} className="initiation" />
                  </div>
                </div>
              )}
            </Col>
            <Col lg={6} className="order-lg-1">
              <Card className="card-inner">
                <Card.Body>
                  <div className="amount-section">
                    <span className="card-header-text">
                      Get Chance to Win '
                      {web3.utils.fromWei(`${lotterylist.totalPrize}`, "ether")}
                      ' POL
                    </span>
                  </div>
                  <div className="partners-section">
                    <div className="logo-section">
                      <img src={imageUrl} className="partnerlogoMissile" />
                    </div>
                    <div className="date-section d-none d-sm-block">
                      <span>
                        Start Date :{" "}
                        {moment
                          .unix(datelist?.startTime)
                          .format("DD/MM/YYYY hh:mma")}{" "}
                        {/* -{" "}
                        {moment
                          .unix(datelist?.endTime)
                          .format("DD/MM/YYYY hh:mma")} */}
                      </span>
                    </div>
                    <div className="logo-section">
                      <img src={AutoLogo} className="partnerlogoMissile" />
                    </div>
                  </div>
                  <div className="date-section d-sm-none dateformobile">
                    <span>
                      {moment
                        .unix(datelist?.startTime)
                        .format("DD/MM/YYYY hh:mma")}{" "}
                      -{" "}
                      {moment
                        .unix(datelist?.endTime)
                        .format("DD/MM/YYYY hh:mma")}
                    </span>
                  </div>
                  <div className="no-to-select">
                    <Input
                      className="inputField"
                      type="text"
                      value={selectNumber}
                      // maxLength={lotterylist.pickNumbers}
                    />
                    {numSelected >= maxSelected && (
                      <div className="error-texttt">
                        You have selected the maximum number of characters.
                      </div>
                    )}
                    {selectNumber.length > maxSelected && (
                      <div className="error-textt">
                        Number cannot exceed {maxSelected} characters.
                      </div>
                    )}
                    <div className="numbers">
                      {buttonValues.map((row, rowIndex) => (
                        <div className="button-row" key={rowIndex}>
                          {row.map((value, columnIndex) => (
                            <button
                              key={columnIndex}
                              onClick={() => handleButtonClick(value)}
                              className={
                                value === "Enter"
                                  ? "enter"
                                  : value === "Delete"
                                  ? "delete"
                                  : "number"
                              }
                              disabled={
                                isDisabled &&
                                value !== "Enter" &&
                                value !== "Delete"
                              }
                            >
                              {value}
                            </button>
                          ))}
                        </div>
                      ))}
                    </div>
                  </div>{" "}
                  <div className="draw-date">
                    <span>
                      Select combination of {lotterylist.pickNumbers} codes
                    </span>
                  </div>
                  <div className="btn-section">
                    <Button
                      onClick={buy}
                      style={{
                        cursor: "pointer",
                        height: "auto",
                        width: "230px",
                      }}
                      disabled={!isEnterClicked && selectNumber == ""}
                    >
                      <b style={{ wordBreak: "break-all" }}>
                        Buy for{" "}
                        {web3.utils.fromWei(`${lotterylist.entryFee}`, "ether")}{" "}
                        POL
                      </b>
                    </Button>
                  </div>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
      <SuccessPopup
        show={showSuccess}
        setShowModal={(e) => setShowSuccess(e)}
        message={msg}
        redirectPath={Path.profile}
        btnName={"Go to profile"}
      />

      <FailedPopup show={showFail} setShowModal={(e) => setShowFail(e)} />
    </div>
  );
};

export default Missile;
