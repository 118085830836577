import React from "react";
import { Container, Col, Row, Card, Button } from "react-bootstrap";
import moment from "moment";
import getImageUrlByHash from "../../static/logoUtils";
import web3 from "../Service/web3";
import AutoLogo from "../../static/Images/logo.png";
const ListCard = ({ item, history, partner, partnerLogo }) => {
  const imageUrl = getImageUrlByHash(partner?.logoHash, partnerLogo);

  return (
    <Card
      className="lottery-card"
      // onClick={() => history.push(`/lottery/${item.lotteryId}`)}
    >
      <div className="outercircle">
        <div className="circle1">
          {" "}
          <span className="coinnumber1">{item.capacity}</span>
        </div>
        <div className="circle2">
          <span className="coinnumber">{item.pickNumbers}</span>
        </div>
      </div>
      {(item.status === "3" || item.status === "5") && (
        <div className="lottery-tag">
          <span>Rollover</span>
        </div>
      )}
      <Card.Body>
        <Card.Title
          className="whitetext"
          //  style={{ color: textcolor }}
        >
          {moment.unix(item && item.deployedOn).format("DDMMYYYY")}-
          {item.lotteryId}
        </Card.Title>
        <p>Chance to win</p>
        <Card.Title className={"amount"}>
          {web3.utils.fromWei(`${item.totalPrize}`, "ether")}POL
        </Card.Title>
        {(partner?.logoHash !== undefined || partner?.logoHash !== "") && (
          <img src={imageUrl} className="partnerlogo" />
        )}
        {/* <Card.Subtitle
          className="mt-2 mb-2 whitetext2"
          //style={{ color: textcolor }}
        >
          Ends on {moment.unix(item.endTime).format("DD/MM/YYYY hh:mma")}
        </Card.Subtitle> */}
      </Card.Body>
      <a
        onClick={() => history.push(`/lottery/${item.lotteryId}`)}
        className="mybtn1 lotterycardbtn"
        style={{ textDecoration: "none", border: "2px solid #0f8afd" }}
      >
        Play Now!
      </a>
    </Card>
  );
};
export default ListCard;
