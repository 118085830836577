import React, { Component } from "react";

import { Container, Col, Row, Card, Button } from "react-bootstrap";
import { Nav, NavItem, NavLink } from "reactstrap";
import moment from "moment";
import giftcardcinstance from "../../Service/giftcardcinstance";
import cinstance from "../../Service/randomcinstance";
import web3 from "../../Service/web3";
import NavBar from "../../Components/NavBar";
class Profile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      seed: "",
      loading: false,
      Randomresult: "",
      winner: "",
      lotterylist: [],
      referral: "",
      giftcardlist: [],
      activeTab: "1",
      BETBalance: "",
    };
  }
  async componentDidMount() {
    var lotterylist = [];
    var counts = 0;
    if (window.ethereum) {
      window.web3 = web3;
      let account = await web3.eth.getAccounts();
      if (account.length !== 0) {
        // cinstance.getPastEvents(
        //   "LotteryBought",
        //   {
        //     filter: { useraddress: `${account[0]}` },
        //     fromBlock: 21694796,
        //     toBlock: "latest",
        //   },
        //   (errors, events) => {
        //     if (!errors) {
        //       events.forEach((i) => lotterylist.push(i.returnValues));
        //       this.setState({ lotterylist });
        //     }
        //   }
        // );
        let list = [];
        cinstance.methods
          .getUserlotteries(account[0])
          .call()
          .then((response) => {
            response?.map((i) => {
              cinstance.methods
                .lottery(i)
                .call()
                .then((response2) => {
                  list.push(response2);
                  this.setState({ lotterylist: list });
                });
            });
          })
          .catch((err) => console.log("pepep", err));

        let giftcard = [];
        giftcardcinstance.methods
          .getGiftCardsByBuyer(account[0])
          .call()
          .then((response) => {
            giftcard.push(response);
            this.setState({ giftcardlist: giftcard });
          })
          .catch((err) => console.log("pepep", err));

        cinstance.methods
          .refereeEarned(account[0])
          .call()
          .then((response) => {
            this.setState({ referral: response });
          })
          .catch((err) => console.log("pepep", err));

        cinstance.methods
          .tokenearned(account[0])
          .call()
          .then((response) => {
            this.setState({ winner: response });
          })
          .catch((err) => console.log("pepep", err));

        // giftcardcinstance.methods
        // .getBetTokenBalance(account[0])
        // .call()
        // .then((res) => {
        //  this.setState({ BETBalance: res})
        // });
      }
    } else {
      alert("Connect to wallet");
    }

    const searchParams = new URLSearchParams(this.props.location.search);
    const giftCardPurchased = searchParams.get("giftCardPurchased");
    if (giftCardPurchased === "true") {
      this.setState({ activeTab: "2" });
    }
  }

  toggleTab = (tab) => {
    if (this.state.activeTab !== tab) {
      this.setState({ activeTab: tab });
    }
  };

  render() {
    const { lotterylist, giftcardlist, activeTab } = this.state;
    return (
      <div>
        <div fluid className="bgImage p-0 m-0">
          <NavBar />
          <Container>
            <Row className="justify-content-center mt-4">
              <Col>
                <h3
                  className="text-center section-title mt-2"
                  style={{ fontWeight: "600", color: "#444" }}
                >
                  {activeTab === "1" ? "My Lottery List" : "My Gift Card List"}
                </h3>
              </Col>
            </Row>
            {(lotterylist.length != 0 || giftcardlist.length != 0) && (
              <Row className="mx-auto ml-4 mb-4 justify-content-center">
                <Nav tabs className="lottery-tabs">
                  <NavItem>
                    <NavLink
                      className={activeTab === "1" ? "active" : ""}
                      onClick={() => {
                        this.toggleTab("1");
                      }}
                    >
                      My Lottery List
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      className={activeTab === "2" ? "active" : ""}
                      onClick={() => {
                        this.toggleTab("2");
                      }}
                    >
                      My Gift Card List
                    </NavLink>
                  </NavItem>
                </Nav>
              </Row>
            )}
            <Row>
              <Col lg={4} className="mb-4">
                <div className="addr">
                  Tokens Earned From Gift Cards :{" "}
                  {Number(this.state.BETBalance / 10 ** 18).toFixed(5)} BET
                </div>
              </Col>
              <Col lg={2} className="mb-4">
                <Button
                  onClick={async () => {
                    let account = await web3.eth.getAccounts();
                    giftcardcinstance.methods
                      .withdrawUserBetTokens(account[0], this.state.BETBalance)
                      .send({ from: account[0] })
                      .then((response) => {
                        window.location.reload();
                      })
                      .catch((err) => console.log("pepep", err));
                  }}
                >
                  Withdraw
                </Button>
              </Col>
              <Col lg={4} className="mb-4">
                <div className="addr">
                  Tokens Earned From Lottery :{" "}
                  {Number(this.state.winner / 10 ** 18).toFixed(5)} BET
                </div>
              </Col>
              <Col lg={2} className="mb-4">
                <Button
                  onClick={async () => {
                    let account = await web3.eth.getAccounts();
                    cinstance.methods
                      .redeemTokens()
                      .send({ from: account[0] })
                      .then((response) => {
                        window.location.reload();
                      })
                      .catch((err) => console.log("pepep", err));
                  }}
                >
                  Withdraw
                </Button>
              </Col>
              <Col lg={4} className="mb-4">
                <div className="addr">
                  Referral Bonus Earned :{" "}
                  {Number(this.state.referral / 10 ** 18).toFixed(5)} POL
                </div>
              </Col>
              <Col lg={2} className="mb-4">
                <Button
                  onClick={async () => {
                    let account = await web3.eth.getAccounts();
                    cinstance.methods
                      .withdrawrefereecommission()
                      .send({ from: account[0] })
                      .then((response) => {
                        window.location.reload();
                      })
                      .catch((err) => console.log("pepep", err));
                  }}
                >
                  Withdraw
                </Button>
              </Col>
            </Row>
            <Row className="mx-auto mt-4">
              {activeTab === "1" &&
                lotterylist.map((i) => (
                  <Col lg="4" md="4">
                    <div className="overview-item ">
                      <p>
                        <b>Lottery No:</b>{" "}
                        {moment.unix(i.deployedOn).format("DDMMYYYY")} -{" "}
                        {i.lotteryId}
                        <br />
                        <b>Type: </b>
                        {i?.lotteryType == 0
                          ? "Autobet Revolver"
                          : i?.lotteryType == 1
                          ? "Autobet Mine"
                          : i?.lotteryType == 2
                          ? "Autobet MRL"
                          : "Autobet Missile"}
                        <br />
                        <b>Fee Paid: </b>{" "}
                        {web3.utils.fromWei(`${i?.entryFee}`, "ether")} POL
                      </p>
                    </div>
                  </Col>
                ))}

              {activeTab === "2" &&
                giftcardlist.length > 0 &&
                giftcardlist[0].map((giftCard) => (
                  <Col lg="4" md="4" key={giftCard.giftCardId}>
                    <div className="overview-item">
                      <p>
                        <b>Card No:</b> {giftCard.giftCardId}
                        <br />
                        <b>Type: </b>{" "}
                        {giftCard.isDonation === true ? "Donation" : "Sell"}
                        <br />
                        <b>Amount Received: </b>
                        {web3.utils.fromWei(
                          `${giftCard.BETValue}`,
                          "ether"
                        )}{" "}
                        BET
                        <br />
                        <b>Fee Paid: </b>{" "}
                        {web3.utils.fromWei(`${giftCard?.ETHPrice}`, "ether")}{" "}
                        POL <br />
                      </p>
                    </div>
                  </Col>
                ))}
            </Row>
          </Container>
          {/* <Container>
            <div className="container register">
              <div>
                <Row>
                  <Col lg="12"> */}
          {/* <Card
                        className="Card-style"
                        className="overview-item "
                        style={{
                          alignSelf: "left",
                          margin: "auto",
                          maxWidth: "500px",
                        }}
                      >
                        <h1
                          style={{
                            fontSize: "30px",
                            FontWeight: "bold",
                            textAlign: "center",
                          }}
                        >
                          Profile Details
                        </h1>
                        <CardBody>
                          <Form>
                            <Form.Row>
                              <Form.Group
                                as={Col}
                                md="12"
                                controlId="validationCustom01"
                              >
                                <Form.Label>
                                  <b>Name : </b>
                                </Form.Label>
                              </Form.Group>

                              <Form.Group
                                as={Col}
                                md="12"
                                controlId="validationCustom03"
                              >
                                <Form.Label>
                                  <b>Email : </b>
                                </Form.Label>
                              </Form.Group>
                            </Form.Row>
                            <Form.Row>
                              <Form.Group
                                as={Col}
                                md="12"
                                controlId="validationCustom01"
                              >
                                <Form.Label>
                                  <b>Mobile :</b>
                                </Form.Label>
                              </Form.Group>
                            </Form.Row>

                            {/* <Button type="submit" style={{width:'100%'}}>Sign Up</Button> 
                            <div
                              style={{ margin: "auto", textAlign: "center" }}
                            ></div>
                          </Form>
                        </CardBody>
                      </Card> */}
          {/* </Col>
                </Row>
              </div>
            </div>
          </Container> */}
        </div>
      </div>
    );
  }
}

export default Profile;
