import React, { useState, useEffect } from "react";
import { Card, CardBody, Dropdown, Input } from "reactstrap";
import { Form, Row, Col, Container, Button, Modal } from "react-bootstrap";
import ButtonCommon from "../../Components/ButtonCommon";
import { useHistory } from "react-router-dom";
import cinstance from "../../Service/randomcinstance";
import usercinstance from "../../Service/usercinstance";
import web3 from "../../Service/web3";
import OrgNavBar from "../../Components/OrgNavBar";
import DateTimePicker from "react-datetime-picker";
import moment from "moment";
import Warnings from "../../Components/Warning";
import Loaders from "../../Components/Loaders";
import FailedPopup from "../../Components/FailedPopup";
import SuccessPopup from "../../Components/SuccessPopup";
import BigNumber from "bignumber.js";
const Createlottery = (props) => {
  const [entryfee, setentryfee] = useState("");
  const [totalnumber, settotalnumber] = useState("1");
  const [totalPrize, settotalPrize] = useState("");
  const [startTime, setstartTime] = useState(
    new Date(Date.now() + 8 * 24 * 60 * 60)
  );
  const [endtime, setendtime] = useState(
    new Date(Date.now() + 20 * 24 * 60 * 60)
  );
  const [drawdate, setdrawdate] = useState(
    new Date(Date.now() + 20 * 24 * 60 * 60)
  );
  const [capacity, setcapacity] = useState("");
  const [lotteryCreateFee, setlotteryCreateFee] = useState("");
  const [minPlayers, setminPlayers] = useState("0");
  const [message, setMessage] = useState("");
  let history = useHistory();
  const [error, seterror] = useState(false);
  const [address, setAddress] = useState("");
  const [modalOpen, setmodalOpen] = useState(false);
  const [Name, setName] = useState("");

  const [validated, setValidated] = useState(false);
  const [loading, setloading] = useState(false);
  const [active, setactive] = useState(false);
  const [approved, setapproved] = useState(false);

  const [lotterytype, setlotterytype] = useState("0");
  const [show, setShow] = useState(true);

  const [partnerlist, setPartnerlist] = useState([]);
  const [selectPartner, setSelectPartner] = useState("");
  const [Rollover, setRollover] = useState("");
  const [Rolloverday, setRolloverdays] = useState("");

  const [unlockCode, setUnlockCode] = useState("");
  const [showFail, setShowFail] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);
  const [partnerPercent, setPartnerPercent] = useState(false);

  const onSendmsg = async (event) => {
    const form = event.currentTarget;
    let val = true;
    event.preventDefault();
    event.stopPropagation();

    if (Number(entryfee) >= Number(totalPrize)) {
      // console.log("1");
      val = false;
    }
    if (Number(entryfee) < 0) {
      val = false;
      // console.log("2");
    }
    if (Number(totalnumber) > Number(capacity)) {
      val = false;
      // console.log("3");
    }
    if (Number(totalnumber) < 1) {
      // console.log("4");

      val = false;
    }
    if (partnerlist.length === 0 && selectPartner === "") {
      val = false;
      alert("No partners available, Please check after some time.");
    }
    if (
      (unlockCode < 1 || unlockCode > 10 || unlockCode === "") &&
      lotterytype == "3"
    ) {
      val = false;
    }
    if (
      parseFloat(Rolloverday) < 1 &&
      (lotterytype == "2" || lotterytype == "3")
    ) {
      val = false;
    }
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    }
    setValidated(true);
    let days = Rolloverday;
    // console.log(Rolloverday);
    if (form.checkValidity() === true && val) {
      let currentDT = moment().unix();
      let st = moment(startTime).unix();
      let et = moment(endtime).unix();
      let dt = moment(drawdate).unix();
      if (Rolloverday == "" && (lotterytype !== "2" || lotterytype !== "3")) {
        days = 0;
      } else {
        days = Number(Number(Rolloverday) * 86400).toFixed();
      }

      if (currentDT > st || currentDT > et || currentDT > dt) {
        alert("Datetime cannot be a datetime before the current date!");
        event.preventDefault();
      } else {
        let rolloverValue = Number(Rollover);
        if (rolloverValue > 50) {
          alert("Rollover value should not be greater than 50");
          event.preventDefault();
        } else {
          setloading(true);
          let entryfee1 = web3.utils.toWei(`${entryfee}`, "ether");
          let totalPrize1 = web3.utils.toWei(`${totalPrize}`, "ether");
          let tl =
            (Number(totalPrize1) * Number(lotteryCreateFee)) / 100 +
            Number(totalPrize1);
          let lotterytype1 = Number(lotterytype);
          if (st < et) {
            setactive(true);
            event.preventDefault();
            let account = await web3.eth.getAccounts();
            console.log(
              entryfee1,
              totalnumber,
              totalPrize1,
              st,
              et,
              dt,
              capacity,
              rolloverValue,
              days,
              partnerPercent,
              selectPartner,
              lotterytype1,
              tl,
              "sdsiddjaid"
            );
            cinstance.methods
              .createLottery(
                entryfee1,
                totalnumber,
                totalPrize1,
                st,
                et,
                dt,
                capacity,
                rolloverValue,
                days,
                partnerPercent,
                selectPartner,
                lotterytype1
              )
              .send({ from: account[0], value: tl })
              .then((res) => {
                setloading(false);
                setShowSuccess(true);
              })
              .catch(() => {
                setloading(false);
                setShowFail(true);
              });
          } else {
            event.preventDefault();
            setloading(false);
            seterror("End time should be greater than start time");
          }
        }
      }
    }
  };

  const getList = async () => {
    var partnerlists = [];

    cinstance.methods
      .partnerId()
      .call()
      .then((res) => {
        for (var i = 0; i <= res; i++) {
          cinstance.methods
            .partnerids(i)
            .call()
            .then((responseAdd) => {
              cinstance.methods
                .partnerbyaddr(responseAdd)
                .call()
                .then((response) => {
                  if (
                    response.partnerAddress !=
                    "0x0000000000000000000000000000000000000000"
                  ) {
                    partnerlists.push(response);
                    setPartnerlist(() => [...partnerlists]);
                  }
                });
            })
            .catch((err) => console.log("pepep", err));
        }
      })
      .catch((err) => console.log("res", err));
  };
  useEffect(() => {
    async function fetchData() {
      if (window.ethereum) {
        window.web3 = web3;
        let account = await web3.eth.getAccounts();
        window.ethereum.on("accountsChanged", () => {
          window.location.reload();
        });
        if (account.length !== 0) {
          setAddress(account[0]);
          setloading(true);
          usercinstance.methods
            .organisationbyaddr(account[0])
            .call()
            .then((res) => {
              setloading(false);
              setapproved(res);
            })
            .catch((err) => console.log("res", err));
          cinstance.methods
            .lotteryCreateFee()
            .call()
            .then((res) => {
              setloading(false);

              setlotteryCreateFee(res);
            })
            .catch((err) => console.log("res", err));
        } else {
          seterror(true);
          setloading(false);
        }
      } else {
        setloading(false);

        seterror(true);
      }
    }
    fetchData();
    getList();
  }, []);
  // console.log("rollover day", Rolloverday)
  return (
    <div>
      <OrgNavBar />
      {error && <Warnings />}
      {loading && <Loaders />}

      <section
        className="register-section mspace"
        style={{ alignItems: "start", paddingTop: 50 }}
      >
        <div className="container  createLottery_container">
          {approved && !approved.active && !loading && (
            <h3 className="mt-5 text-left pl-5 ">Account Not Approved</h3>
          )}
          {approved && approved.active && (
            <div className="bgImage  p-o m-0" fluid>
              <Row>
                <Col lg="12">
                  <Card
                    className=" ccard Card-style"
                    style={{
                      margin: "auto",
                      borderRadius: 20,
                      padding: 20,
                      maxWidth: "550px",
                      boxShadow: "0 0 10px 0 rgb(0 0 0 / 15%)",
                    }}
                  >
                    <h1
                      style={{
                        marginTop: 30,
                        fontSize: "28px",
                        fontWeight: 600,
                        textAlign: "center",
                      }}
                    >
                      Create A New Lottery
                    </h1>
                    <CardBody style={{ paddingBottom: 0 }}>
                      <Form
                        noValidate
                        validated={validated}
                        onSubmit={onSendmsg}
                        className="CreateLotteryForm"
                      >
                        <Form.Row classNam="mx-auto">
                          <Form.Group
                            as={Col}
                            md="6"
                            controlId="validationCustom01"
                          >
                            <Form.Label className="mr-5">
                              Lottery Type
                            </Form.Label>
                            <Form.Control
                              aria-label="Default select example"
                              as={"select"}
                              onChange={(event) => {
                                setlotterytype(event.target.value);
                                settotalnumber(1);
                              }}
                            >
                              <option value={"0"}>Autobet Revolver</option>
                              <option value={"1"}>Autobet Mine</option>
                              <option value={"2"}>Autobet MRL</option>
                              <option value={"3"}>Autobet Missile</option>
                            </Form.Control>
                          </Form.Group>
                          <Form.Group
                            as={Col}
                            md="6"
                            controlId="validationCustom01"
                          >
                            <Form.Label className="mr-5">
                              Select Partner
                            </Form.Label>
                            <Form.Control
                              aria-label="Default select example"
                              required
                              as="select"
                              value={selectPartner}
                              onChange={(e) => {
                                setSelectPartner(e.target.value);
                              }}
                              disabled={partnerlist.length === 0}
                            >
                              {partnerlist.length === 0 ? (
                                <option hidden value="">
                                  No partners available
                                </option>
                              ) : (
                                <>
                                  <option hidden value="">
                                    Select Partner
                                  </option>
                                  {partnerlist.map((item) => (
                                    <option
                                      value={item.partnerAddress}
                                      key={item.partnerAddress}
                                    >
                                      {item.name}
                                    </option>
                                  ))}
                                </>
                              )}
                            </Form.Control>
                            <Form.Control.Feedback type="invalid">
                              Select Partner
                            </Form.Control.Feedback>
                          </Form.Group>
                        </Form.Row>
                        <Form.Row>
                          <Form.Group
                            as={Col}
                            md="6"
                            controlId="validationCustom01"
                          >
                            <Form.Label>Ticket Fee</Form.Label>
                            <Form.Control
                              required
                              type="number"
                              placeholder="Fee in POL"
                              value={entryfee}
                              onChange={(event) => {
                                setentryfee(event.target.value);

                                let ep = Number(event.target.value);
                                if (totalPrize != "") {
                                  setminPlayers(
                                    BigNumber(
                                      BigNumber(totalPrize).dividedBy(ep)
                                    ).plus(
                                      BigNumber(
                                        BigNumber(
                                          BigNumber(totalPrize).multipliedBy(10)
                                        ).dividedBy(ep)
                                      ).dividedBy(100)
                                    )?.c?.[0]
                                  );
                                }
                              }}
                              isValid={
                                validated && entryfee < parseFloat(totalPrize)
                              }
                              isInvalid={
                                validated &&
                                (entryfee < 0 ||
                                  entryfee >= parseFloat(totalPrize))
                              }
                            />
                            <Form.Control.Feedback type="invalid">
                              {entryfee < 0
                                ? "Ticket fee cannot be negative"
                                : entryfee >= parseFloat(totalPrize)
                                ? "Ticket fee cannot be greater than winning prize amount"
                                : "Enter Ticket Fee"}
                            </Form.Control.Feedback>
                          </Form.Group>
                          <Form.Group
                            as={Col}
                            md="6"
                            controlId="validationCustom01"
                          >
                            <Form.Label>Winner Prize Amount</Form.Label>
                            <Form.Control
                              required
                              type="number"
                              placeholder="Enter Amount in POL "
                              value={totalPrize}
                              onChange={(event) => {
                                let ep = Number(event.target.value);

                                settotalPrize(event.target.value);
                                if (entryfee != "") {
                                  setminPlayers(
                                    BigNumber(
                                      BigNumber(ep).dividedBy(entryfee)
                                    ).plus(
                                      BigNumber(
                                        BigNumber(
                                          BigNumber(ep).multipliedBy(10)
                                        ).dividedBy(entryfee)
                                      ).dividedBy(100)
                                    )?.c?.[0]
                                  );
                                }
                              }}
                              isInvalid={validated && totalPrize < 0}
                            />
                            <Form.Control.Feedback type="invalid">
                              {totalPrize < 0
                                ? "Prize amount cannot be negative"
                                : "Enter Prize Amount"}
                            </Form.Control.Feedback>
                          </Form.Group>
                        </Form.Row>
                        {lotterytype != "3" ? (
                          <Form.Row>
                            <Form.Group
                              as={Col}
                              md="6"
                              controlId="validationCustom01"
                              className="noselected"
                            >
                              <Form.Label>Numbers to be selected</Form.Label>
                              <Form.Control
                                disabled={
                                  lotterytype === "0" || lotterytype === "1"
                                }
                                required
                                type="number"
                                placeholder="Enter Number"
                                value={totalnumber}
                                onChange={(event) => {
                                  settotalnumber(event.target.value);
                                }}
                                isValid={
                                  validated &&
                                  totalnumber < parseFloat(capacity)
                                }
                                isInvalid={
                                  validated &&
                                  (totalnumber < 0 ||
                                    totalnumber >= parseFloat(capacity))
                                }
                                min={1}
                              />
                              <Form.Control.Feedback type="invalid">
                                {totalnumber < 1
                                  ? "Numbers to be selected cannot be less than 1"
                                  : totalnumber >= parseFloat(capacity)
                                  ? "Numbers to be selected cannot be greater than lottery size"
                                  : "Enter numbers to be selected"}
                              </Form.Control.Feedback>
                            </Form.Group>
                            <Form.Group
                              as={Col}
                              md="6"
                              controlId="validationCustom01"
                            >
                              <Form.Label>Lottery size</Form.Label>
                              <Form.Control
                                required
                                type="number"
                                placeholder="Enter total Numbers"
                                defaultValue="Mark"
                                min={
                                  lotterytype === "0" || lotterytype === "2"
                                    ? "5"
                                    : "3"
                                }
                                value={capacity}
                                onChange={(event) =>
                                  setcapacity(event.target.value)
                                }
                              />
                              <Form.Control.Feedback type="invalid">
                                {capacity === ""
                                  ? "Please enter the lottery size"
                                  : (lotterytype === "0" ||
                                      lotterytype === "2") &&
                                    parseInt(capacity) < 5
                                  ? "Lottery size should be at least 5"
                                  : parseInt(capacity) < 3
                                  ? "Lottery size should be at least 3"
                                  : ""}
                              </Form.Control.Feedback>
                            </Form.Group>
                          </Form.Row>
                        ) : (
                          <Form.Row>
                            <Form.Group
                              as={Col}
                              md="12"
                              controlId="validationCustom01"
                            >
                              <Form.Label>Unlock Code Length</Form.Label>
                              <Form.Control
                                required
                                type="number"
                                placeholder="Enter Unlock Code Length"
                                value={unlockCode}
                                onChange={(event) => {
                                  setUnlockCode(event.target.value);
                                  settotalnumber(event.target.value);
                                  setcapacity(event.target.value + 1);
                                }}
                                maxLength={2}
                                isInvalid={
                                  validated &&
                                  (unlockCode < 1 || unlockCode > 10)
                                }
                              />
                              <Form.Control.Feedback type="invalid">
                                {unlockCode < 1
                                  ? "Value must be greater than or equal to 1"
                                  : unlockCode > 10
                                  ? "Value must be less than or equal to 10"
                                  : "Enter unlock code"}
                              </Form.Control.Feedback>
                            </Form.Group>
                          </Form.Row>
                        )}
                        <Form.Row className="create-date">
                          <Form.Group
                            as={Col}
                            md="6"
                            controlId="validationCustom01"
                          >
                            <Form.Label>Start Date</Form.Label>
                            <DateTimePicker
                              className="dats date-input"
                              format={"dd-MM-yy h:mma"}
                              calendarIcon={null}
                              clearIcon={null}
                              disableClock={true}
                              onChange={(value) => setstartTime(value)}
                              value={startTime}
                            />
                          </Form.Group>
                          {(lotterytype == "2" || lotterytype == "3") && (
                            <Form.Group
                              as={Col}
                              md="6"
                              controlId="validationCustom01"
                            >
                              <Form.Label>Rollover/Result Date</Form.Label>
                              <DateTimePicker
                                calendarIcon={null}
                                clearIcon={null}
                                disableClock={true}
                                format={"dd-MM-yy h:mma"}
                                className="dats date-input"
                                onChange={(value) => {
                                  setendtime(value);
                                  setdrawdate(value);
                                }}
                                value={endtime}
                              />
                            </Form.Group>
                          )}
                          {lotterytype == "0" && (
                            <Form.Group
                              as={Col}
                              md="6"
                              controlId="validationCustom01"
                            >
                              <Form.Label>Rollover in %</Form.Label>
                              <Form.Control
                                required
                                type="number"
                                placeholder="Enter Amount in % "
                                max="50"
                                value={Rollover}
                                onChange={(event) =>
                                  setRollover(event.target.value)
                                }
                                isInvalid={
                                  validated && parseFloat(Rollover) < 0
                                }
                              />
                              <Form.Control.Feedback type="invalid">
                                {parseFloat(Rollover) < 0
                                  ? "Rollover cannot be negative"
                                  : parseFloat(Rollover) > 50
                                  ? "Rollover cannot exceed 50%"
                                  : "Enter Rollover %"}
                              </Form.Control.Feedback>
                            </Form.Group>
                          )}
                          {/* {lotterytype != "0" && lotterytype != "1" && lotterytype != "2" && (
                            <Form.Group
                              as={Col}
                              md="6"
                              controlId="validationCustom01"
                            >
                              <Form.Label className="mr-5">
                                Draw Date
                              </Form.Label>
                              <DateTimePicker
                                calendarIcon={null}
                                clearIcon={null}
                                disableClock={true}
                                format={"dd-MM-yy h:mma"}
                                className="dats date-input"
                                onChange={(value) => setdrawdate(value)}
                                value={drawdate}
                              />
                            </Form.Group>
                          )} */}
                          {lotterytype != "0" && lotterytype != "1" && (
                            <Form.Group
                              as={Col}
                              md="6"
                              controlId="validationCustom01"
                            >
                              <Form.Label>Rollover in %</Form.Label>
                              <Form.Control
                                required
                                type="number"
                                placeholder="Enter Amount in % "
                                max="50"
                                value={Rollover}
                                onChange={(event) =>
                                  setRollover(event.target.value)
                                }
                                isInvalid={
                                  validated && parseFloat(Rollover) < 0
                                }
                              />
                              <Form.Control.Feedback type="invalid">
                                {parseFloat(Rollover) < 0
                                  ? "Rollover cannot be negative"
                                  : parseFloat(Rollover) > 50
                                  ? "Rollover cannot exceed 50%"
                                  : "Enter Rollover %"}
                              </Form.Control.Feedback>
                            </Form.Group>
                          )}
                          {(lotterytype == "2" || lotterytype == "3") && (
                            <Form.Group
                              as={Col}
                              md="6"
                              controlId="validationCustom01"
                            >
                              <Form.Label>Rollover in Days</Form.Label>
                              <Form.Control
                                required
                                type="text"
                                placeholder="Enter Rollover in days"
                                value={Rolloverday}
                                onChange={(event) => {
                                  const value = event.target.value;
                                  const regex = /^\d*\.?\d*$/;
                                  if (regex.test(value) || value === "") {
                                    setRolloverdays(value);
                                  }
                                }}
                                isInvalid={
                                  validated && parseFloat(Rolloverday) < 1
                                }
                              />
                              <Form.Control.Feedback type="invalid">
                                {parseFloat(Rolloverday) < 0
                                  ? "Rollover day cannot be negative"
                                  : parseFloat(Rolloverday) < 1
                                  ? "Rollover day cannot be less than 1"
                                  : "Enter Rollover in days"}
                              </Form.Control.Feedback>
                            </Form.Group>
                          )}
                          {selectPartner !== "" && (
                            <Form.Group
                              as={Col}
                              md="6"
                              controlId="validationCustom01"
                            >
                              <Form.Label>Partner's share in %</Form.Label>
                              <Form.Control
                                required
                                type="number"
                                placeholder="Enter Amount in % "
                                min="0"
                                max="100"
                                value={partnerPercent}
                                onChange={(event) =>
                                  setPartnerPercent(event.target.value)
                                }
                              />
                              <Form.Control.Feedback type="invalid">
                                {parseFloat(partnerPercent) < 0
                                  ? "Partner's share cannot be negative"
                                  : parseFloat(partnerPercent) > 100
                                  ? "Enter a value between 1-100 %"
                                  : "Enter Partner's Share"}
                              </Form.Control.Feedback>
                            </Form.Group>
                          )}
                        </Form.Row>

                        <h6 className="mt-3 text-center">
                          Lottery Creation Fee :
                          {(Number(totalPrize) * Number(lotteryCreateFee)) /
                            100 +
                            Number(totalPrize)}{" "}
                          POL
                        </h6>
                        <h6 className="text-center lotterymsgtext">
                          (Winner Prize Amount + {lotteryCreateFee}% of Winner
                          Prize Amount)
                        </h6>
                        {lotterytype == "2" && (
                          <h6 className="text-center lotterymsgtext">
                            Minimum player for draw are {minPlayers}
                          </h6>
                        )}

                        {totalPrize !== "" &&
                          Number(web3.utils.toWei(`${totalPrize}`, "ether")) >=
                            Number(approved.minPrize) &&
                          Number(web3.utils.toWei(`${totalPrize}`, "ether")) <=
                            Number(approved.maxPrize) && (
                            <div
                              style={{
                                margin: "auto",
                                textAlign: "center",
                                marginTop: "20px",
                              }}
                            >
                              <ButtonCommon
                                type="Submit"
                                className="signup-btn"
                                label="Create"
                              />
                            </div>
                          )}
                        {totalPrize !== "" &&
                          !(
                            Number(
                              web3.utils.toWei(`${totalPrize}`, "ether")
                            ) >= Number(approved.minPrize) &&
                            Number(
                              web3.utils.toWei(`${totalPrize}`, "ether")
                            ) <= Number(approved.maxPrize)
                          ) && (
                            <div
                              style={{
                                margin: "auto",
                                textAlign: "center",
                                marginTop: "20px",
                              }}
                              className="lotterymsgtext"
                            >
                              {totalPrize !== "" && (
                                <p>
                                  Invalid winning prize amount should be{" "}
                                  {web3.utils.fromWei(
                                    `${approved && approved.minPrize}`,
                                    "ether"
                                  )}{" "}
                                  -{" "}
                                  {web3.utils.fromWei(
                                    `${approved && approved.maxPrize}`,
                                    "ether"
                                  )}{" "}
                                  POL{" "}
                                </p>
                              )}
                            </div>
                          )}
                        {lotterytype == "1" && (
                          <h6 className="mt-3 text-center lotterymsgtext">
                            Result of lottery will be announced after all
                            tickets are sold out.
                          </h6>
                        )}
                        {lotterytype == "0" && (
                          <h6 className="mt-3 text-center lotterymsgtext">
                            If nobody wins, the prize rolls over in this
                            single-player lottery.
                          </h6>
                        )}
                        {lotterytype == "2" && (
                          <h6 className="mt-3 text-center lotterymsgtext">
                            Winners are announced when minimum tickets are sold
                            and result date is passed. Rollover needs less than
                            the minimum player ticket sales and passed rollover
                            date.
                          </h6>
                        )}
                        {lotterytype == "3" && (
                          <h6 className="mt-3 text-center lotterymsgtext">
                            Each rollover will increase the level by one.
                          </h6>
                        )}
                      </Form>

                      <span className="d-inline-block mb-2 mr-2">
                        <Modal
                          className="PopupStyle"
                          show={modalOpen}
                          onHide={() => setmodalOpen(false)}
                          centered
                        >
                          <Modal.Body>
                            <Container>
                              <Row>
                                <Col lg={12}>
                                  {/* <div className="message-text"></div> */}
                                </Col>
                                <Col lg={12} sm={12} xs={12}>
                                  <div className="btn-div">
                                    <Button
                                      className="submit-btn"
                                      onClick={() => {
                                        window.location.reload();
                                        setmodalOpen(false);
                                      }}
                                    >
                                      OK
                                    </Button>
                                  </div>
                                </Col>
                              </Row>
                            </Container>
                          </Modal.Body>
                        </Modal>
                      </span>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </div>
          )}
        </div>
      </section>
      <SuccessPopup
        show={showSuccess}
        setShowModal={(e) => setShowSuccess(e)}
        message={"Lottery created successfully !"}
        redirectPath={"/creator/mylottery"}
        btnName={"Go to my lottery"}
      />
      <FailedPopup show={showFail} setShowModal={(e) => setShowFail(e)} />
    </div>
  );
};

export default Createlottery;
