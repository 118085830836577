import React, { useState, useEffect } from "react";
import { Container, Col, Row, Modal, Button, Form } from "react-bootstrap";
import { useLocation } from "react-router-dom";
import axios from "axios";
import getImageUrlByHash from "../../../../static/logoUtils";
import cinstance from "../../../Service/randomcinstance";
import giftcardcinstance from "../../../Service/giftcardcinstance";
import web3 from "../../../Service/web3";
import NavBar from "../../../Components/NavBar";
import moment from "moment";
import AutobetLogo from "../../../../static/Images/logo.png";
import { Path } from "../../../../static/Constants";
import SuccessPopup from "../../../Components/SuccessPopup";
import FailedPopup from "../../../Components/FailedPopup";
import Loaders from "../../../Components/Loaders";
import Warnings from "../../../Components/Warning";
import "../../../../static/css/popup.css";
import "./BuyGiftCardStyle.css";

var md5 = require("md5");

const BuyGiftCard = (props) => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [giftcardList, setGiftCardList] = useState({
    BETValue: 0,
    ETHPrice: 0,
  });
  const [voucherCode, setVoucherCode] = useState("");
  const [showFail, setShowFail] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);
  const [validated, setValidated] = useState(false);
  const location = useLocation();
  const currentDT = moment().unix();
  const [giftcardLogoData, setGiftcardLogoData] = useState([]);
  const apiUrl = process.env.REACT_APP_API_KEY;

  useEffect(() => {
    const fetchData = async () => {
      const queryParams = new URLSearchParams(location.search);
      const voucherCodeFromURL = queryParams.get("voucherCode");

      if (voucherCodeFromURL) {
        setVoucherCode(voucherCodeFromURL);
      }

      if (window.ethereum) {
        window.web3 = web3;

        let account = await web3.eth.getAccounts();
        if (account && account.length !== 0) {
          giftcardcinstance.methods
            .giftCards(props.match.params.id)
            .call()
            .then((response) => {
              setGiftCardList(response);
            })
            .catch((err) => console.log("pepep", err));
        } else {
          setError(true);
        }
      } else {
        setError(true);
      }
      setLoading(false);
    };
    fetchData();
  }, [props.match.params.id]);

  const buy = async (event) => {
    const form = event.currentTarget;
    let val = true;
    event.preventDefault();
    event.stopPropagation();

    if (giftcardList.isDonation === true && voucherCode === "") {
      val = false;
    }

    if (
      giftcardList.isDonation === true &&
      voucherCode !== giftcardList.voucherCode
    ) {
      val = false;
    }

    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    }
    setValidated(true);
    if (form.checkValidity() === true && val) {
      if (window.ethereum) {
        window.web3 = web3;
        setLoading(true);
        let account = await web3.eth.getAccounts();

        giftcardcinstance.methods
          .purchaseGiftCard(props.match.params.id, voucherCode)
          .send({
            from: account[0],
            value: giftcardList.ETHPrice,
          })
          .then((res) => {
            setShowSuccess(true);
            setLoading(false);
          })
          .catch((er) => {
            setLoading(false);
            setShowFail(true);
          });
      } else {
        window.ethereum.enable();
        setLoading(false);
      }
    } else {
      event.preventDefault();
      setLoading(false);
    }
  };

  useEffect(() => {
    axios
      .get(`${apiUrl}/giftcardlogos/getAllGiftcardLogos`)
      .then((response) => {
        const giftcardData = response.data.result;
        setGiftcardLogoData(giftcardData);
      });
  }, []);

  const imageUrl = getImageUrlByHash(giftcardList.logoHash, giftcardLogoData);

  return (
    <div className="BuyGiftCard">
      {loading && <Loaders />}
      {error && <Warnings />}
      <NavBar />
      <Container style={{ minHeight: "95vh" }}>
        <Row className=" mb-4 mx-auto justify-content-center">
          <Col lg="10">
            <div className=" mb-3 justify-content-center">
              <h3 className="mb-6 mt-4 font-weight-bold text-danger">
                {" "}
                {giftcardList.isDonation === true
                  ? "Redeem Gift Card"
                  : "Buy Gift Card"}
              </h3>
              {giftcardList.isSold === false &&
                giftcardList.expDate > currentDT && (
                  <Row className="card-inner mt-5">
                    <Col lg="6" className="px-2">
                      <div class="card-body image-section p-4 mx-auto m-5">
                        {giftcardList.logoHash !== undefined &&
                        giftcardList.logoHash !== "" ? (
                          <img src={imageUrl} className="gitfcard-img" />
                        ) : (
                          <img src={AutobetLogo} className="gitfcard-img" />
                        )}
                        <div className="desc-section mt-4">
                          <h6 className="desc-title">Description</h6>
                          <p className="description">{giftcardList.Desc}</p>
                        </div>
                      </div>
                    </Col>

                    <Col lg="6" className="card-details p-2 m-5 mx-auto">
                      <div
                        className="buy-section"
                        style={{ height: "max-content" }}
                      >
                        <Form noValidate validated={validated} onSubmit={buy}>
                          <Form.Row>
                            <Form.Group
                              as={Col}
                              md="12"
                              controlId="validationCustom01"
                            >
                              <Form.Label>Creator</Form.Label>
                              <Form.Control
                                type="text"
                                value={giftcardList.creator}
                                readOnly
                              />
                            </Form.Group>
                          </Form.Row>
                          <Form.Row>
                            <Form.Group
                              as={Col}
                              md="12"
                              controlId="validationCustom01"
                            >
                              <Form.Label>
                                Buy {giftcardList.tokenName}
                              </Form.Label>
                              <Form.Control
                                type="text"
                                value={web3.utils.fromWei(
                                  `${giftcardList.BETValue}`,
                                  "ether"
                                )}
                                readOnly
                              />
                            </Form.Group>
                          </Form.Row>
                          {giftcardList.isDonation === false && (
                            <Form.Row>
                              <Form.Group
                                as={Col}
                                md="12"
                                controlId="validationCustom01"
                              >
                                <Form.Label>Amount POL</Form.Label>
                                <Form.Control
                                  type="text"
                                  value={web3.utils.fromWei(
                                    `${giftcardList.ETHPrice}`,
                                    "ether"
                                  )}
                                  readOnly
                                />
                              </Form.Group>
                            </Form.Row>
                          )}
                          {giftcardList.isDonation === true && (
                            <Form.Row>
                              <Form.Group
                                as={Col}
                                md="12"
                                controlId="validationCustom01"
                              >
                                <Form.Label>Voucher code</Form.Label>
                                <Form.Control
                                  type="text"
                                  required
                                  value={voucherCode}
                                  placeholder="Voucher Code"
                                  onChange={(event) => {
                                    setVoucherCode(event.target.value);
                                  }}
                                  isInvalid={
                                    validated &&
                                    (voucherCode.trim() === "" ||
                                      voucherCode !== giftcardList.voucherCode)
                                  }
                                />
                                <Form.Control.Feedback type="invalid">
                                  {voucherCode.trim() === ""
                                    ? "Enter voucher code"
                                    : "Invalid voucher code"}
                                </Form.Control.Feedback>
                              </Form.Group>
                            </Form.Row>
                          )}
                          <div className="button-section">
                            <Button
                              // onClick={buy}
                              type="Submit"
                              style={{
                                cursor: "pointer",
                                height: "auto",
                                minWidth: "230px",
                                margin: "auto",
                              }}
                            >
                              {giftcardList.isDonation === true ? (
                                <b style={{ wordBreak: "break-all" }}>Redeem</b>
                              ) : (
                                <b style={{ wordBreak: "break-all" }}>
                                  Buy for{" "}
                                  {web3.utils.fromWei(
                                    `${giftcardList.ETHPrice}`,
                                    "ether"
                                  )}{" "}
                                  POL
                                </b>
                              )}
                            </Button>
                          </div>
                        </Form>
                      </div>
                    </Col>
                  </Row>
                )}
              {giftcardList.isSold === true &&
                giftcardList.expDate > currentDT && (
                  <Col lg="6 mb-5" className=" mx-auto p-4">
                    <div class="buy-card  " style={{ height: "100%" }}>
                      <div class="card-body text-center ">
                        <h5 class="text-danger  font-weight-bold p-4 mt-4 mb-4">
                          Gift Card Already Sold !!
                        </h5>
                      </div>
                    </div>
                  </Col>
                )}

              {giftcardList.expDate < currentDT && (
                <Col lg="6 mb-5" className=" mx-auto p-4">
                  <div class="buy-card  " style={{ height: "100%" }}>
                    <div class="card-body text-center ">
                      <h5 class="text-danger  font-weight-bold p-4 mt-4 mb-4">
                        Gift Card Expired !!
                      </h5>
                    </div>
                  </div>
                </Col>
              )}
            </div>
          </Col>
        </Row>
      </Container>

      <SuccessPopup
        show={showSuccess}
        setShowModal={(e) => setShowSuccess(e)}
        message={"Gift Card bought succesfully !"}
        redirectPath={`/profile?giftCardPurchased=true`}
        btnName={"Go to profile"}
      />

      <FailedPopup show={showFail} setShowModal={(e) => setShowFail(e)} />
    </div>
  );
};
export default BuyGiftCard;
