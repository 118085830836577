import React, { Component } from "react";
import { Container, Row, Col, Table } from "react-bootstrap";
import cinstance from "../../Service/randomcinstance";
import web3 from "../../Service/web3";
import AdminNavBar from "../../Components/AdminNavBar";
import moment from "moment";
import Loaders from "../../Components/Loaders";
import ButtonCommon from "../../Components/ButtonCommon";
class LotteryDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      seed: "",
      loading: true,
      Randomresult: "",
      winner: "",
      lotterylist: [],
      datelist: [],
      lotterywinner: [],
      show: false,
    };
  }

  async componentDidMount() {
    var lotterylist = [];
    var datelist = [];
    if (window.ethereum) {
      window.web3 = web3;
      const p = await cinstance.methods.admin().call();
      let account = await web3.eth.getAccounts();

      if (p === account[0]) this.setState({ show: true });

      cinstance.methods
        .lottery(this.props.match.params.id)
        .call()
        .then((response) => {
          if (response) {
            cinstance.getPastEvents(
              "LotteryResult",
              {
                filter: { lotteryId: `${this.props.match.params.id}` },
                fromBlock: 0,
                toBlock: "latest",
              },
              (errors, events) => {
                if (!errors) {
                  this.setState({ WinnersId: events });
                }
              }
            );
          }
          cinstance.methods
            .getLotteryNumbers(this.props.match.params.id)
            .call()
            .then((res) => {
              if (response.lotteryType != "3") {
                let sizes = response.pickNumbers;
                let ticketbought = res.tickets;
                let useraddress = res.useraddress;
                useraddress.forEach((i, index) => {
                  lotterylist.push({
                    useraddress: i,
                    ticketbought: ticketbought?.slice(
                      index * sizes,
                      (index + 1) * sizes
                    ),
                  });
                });
              } else {
                res.useraddress.forEach((i, index) => {
                  lotterylist.push({
                    useraddress: i,
                    ticketbought: res.tickets[index],
                  });
                });
              }
              this.setState({ lotterylist });
            });
          this.setState({ lottery: response, loading: false });

          cinstance.methods
            .lotteryDates(this.props.match.params.id)
            .call()
            .then((responsee) => {
              this.setState({ datelist: responsee });
            })
            .catch((err) => console.log("error", err));
          // if(response.status>1){
          //   cinstance.getPastEvents(
          //     "LotteryResult",
          //     {
          //       filter: { lotteryId: `${this.props.match.params.id}` },
          //       fromBlock: 0 ,
          //       toBlock: "latest"
          //     }, (errors, events) => {
          //          if (!errors) {
          //           this.setState({lotterywinner:events[0].returnValues.useraddressdata})
          //          }
          //      })
          // }
        })
        .catch((err) => console.log("pepep", err));
      // cinstance.getPastEvents(
      //   "LotteryBought",
      //   {
      //     filter: { lotteryId: `${this.props.match.params.id}` },
      //     fromBlock: 0,
      //     toBlock: "latest",
      //   },
      //   (errors, events) => {
      //     if (!errors) {
      //       events.forEach((i) => lotterylist.push(i.returnValues));
      //       this.setState({ lotterylist });
      //     }
      //   }
      // );
    } else {
      alert("Connect to wallet");
    }
  }
  getDraw = async () => {
    if (window.ethereum) {
      window.web3 = web3;
      let account = await web3.eth.getAccounts();
      cinstance.methods
        .getWinnerNumbers("202020", this.props.match.params.id)
        .send({ from: account[0] })
        .then((response) => {
          console.log(response);
        })
        .catch((err) => console.log("pepep", err));
    } else {
      alert("Connect to wallet");
    }
  };
  render() {
    const {
      lottery,
      lotterylist,
      datelist,
      loading,
      lotterywinner,
      WinnersId,
    } = this.state;
    // console.log("winnerId", WinnersId);

    const formatRolloverTime = (seconds) => {
      const days = Math.floor(seconds / (60 * 60 * 24));
      const hours = Math.floor((seconds % (60 * 60 * 24)) / (60 * 60));
      const minutes = Math.floor((seconds % (60 * 60)) / 60);

      let formattedTime = "";

      if (days > 0) {
        formattedTime += `${days} day${days > 1 ? "s" : ""} `;
      }
      if (hours > 0) {
        formattedTime += `${hours} hour${hours > 1 ? "s" : ""} `;
      }
      if (minutes > 0) {
        formattedTime += `${minutes} minute${minutes > 1 ? "s" : ""} `;
      }
      return formattedTime.trim();
    };

    return (
      <div className="lottery-details">
        {loading && <Loaders />}
        <AdminNavBar />
        {!this.state.show && (
          <Container className="bgImage p-o">
            <div className="mspace mt-5">
              <h2>Not an admin</h2>
            </div>
          </Container>
        )}
        {this.state.show && (
          <Container className=" bgImage  p-o " fluid>
            <div className="sidebars">
              <Row>
                <Col lg="12">
                  <h5 style={{ textAlign: "center", marginTop: 20 }}>
                    Lottery Details
                  </h5>
                </Col>
              </Row>
              <Row className=" mt-5 mb-2 mx-auto">
                <Col lg="6">
                  <p className="mt-1 mb-1 address-text">
                    Created By : {lottery && lottery.ownerAddress}
                  </p>
                  <p className="mt-1 mb-1 ">
                    Id :{" "}
                    {moment
                      .unix(lottery && lottery.deployedOn)
                      .format("DDMMYYYY")}{" "}
                    - {lottery && lottery.lotteryId}
                  </p>
                  {lottery?.lotteryType === "2" && (
                    <p className="mt-1 mb-1 ">
                      Minimum Players: {lottery && lottery.minPlayers}
                    </p>
                  )}
                  {lottery?.lotteryType !== "3" && (
                    <p className="mt-1 mb-1 ">
                      Total Numbers : {lottery && lottery.capacity}
                    </p>
                  )}
                  <p className="mt-1 mb-1 ">
                    Total Numbers to Pick: {lottery && lottery.pickNumbers}
                  </p>
                  {lottery && lottery.entryFee && (
                    <p className="mt-1 mb-1 ">
                      Entry Fee:{" "}
                      {web3.utils.fromWei(`${lottery.entryFee}`, "ether")}POL
                    </p>
                  )}
                  {lottery?.lotteryType !== "1" && (
                    <p className="mt-1 mb-1 ">
                      Rollover: {lottery?.rolloverperct}%
                    </p>
                  )}

                  {(lottery?.lotteryType === "1" ||
                    lottery?.lotteryType === "2") && (
                    <p className="mt-1 mb-1">
                      Winner Number:{" "}
                      {WinnersId?.length === 0
                        ? "In progress"
                        : WinnersId?.[0]?.returnValues?.n ||
                          WinnersId?.[0]?.returnValues?.number
                            ?.split("-")
                            .slice(0, -1)
                            .reverse()
                            .join("-")}
                    </p>
                  )}

                  {lottery?.partnershare && lottery?.lotteryType === "3" && (
                    <p className="mt-1 mb-1 ">
                      Partners Share: {lottery?.partnershare}%
                    </p>
                  )}
                </Col>
                <Col lg="6">
                  <p className="mt-1 mb-1 ">
                    Lottery Type:{" "}
                    {lottery && lottery.lotteryType == 0
                      ? "Autobet Revolver"
                      : lottery && lottery.lotteryType == 1
                      ? "Autobet Mine"
                      : lottery && lottery.lotteryType == 2
                      ? "Autobet MRL"
                      : "Autobet Missile"}
                  </p>
                  <p className="mt-1 mb-1 ">
                    Start Date:{" "}
                    {moment
                      .unix(datelist && datelist.startTime)
                      .format("DD/MM/YYYY hh:mma")}
                  </p>

                  {
                    <p className="mt-1 mb-1 ">
                      Rollover Date:{" "}
                      {moment
                        .unix(datelist && datelist.drawTime)
                        .format("DD/MM/YYYY hh:mma")}
                    </p>
                  }
                  {/* <p className="mt-1 mb-1 ">
                  Draw Date:{" "}
                  {moment
                    .unix(datelist && datelist.drawTime)
                    .format("DD/MM/YYYY hh:mma")}
                </p> */}
                  <p className="mt-1 mb-1 ">
                    Status:{" "}
                    {lottery && lottery.status == 0
                      ? "Open"
                      : lottery && lottery.status == 1
                      ? "Close"
                      : lottery && lottery.status == 4
                      ? "Rollover Needed"
                      : lottery && lottery.status == 5
                      ? "Rollover Done"
                      : lottery && lottery.status == 6
                      ? "Creating in progress"
                      : "Result Done"}
                  </p>
                  {lottery && lottery.totalPrize && (
                    <p className="mt-1 mb-1 ">
                      Winner Amount:{" "}
                      {web3.utils.fromWei(`${lottery.totalPrize}`, "ether")}POL
                    </p>
                  )}
                  {lottery?.partnershare && lottery?.lotteryType !== "3" && (
                    <p className="mt-1 mb-1 ">
                      Partners Share: {lottery?.partnershare}%
                    </p>
                  )}
                  {(lottery?.lotteryType === "2" ||
                    lottery?.lotteryType === "3") && (
                    <p className="mt-1 mb-1 ">
                      Rollover Duration:{" "}
                      {formatRolloverTime(datelist?.rolloverdays)}
                    </p>
                  )}
                </Col>
              </Row>
              <Row className="mx-auto mb-3 justify-content-center">
                <Col lg="10">
                  {
                    lottery &&
                    lottery.lotteryWinner !==
                      "0x0000000000000000000000000000000000000000" ? (
                      <p className="winner-address">
                        Winner: {lottery.lotteryWinner}
                      </p>
                    ) : (
                      <p className="winner-address">Winner: No Winner</p>
                    )
                    /* {&&lotterywinner.map((i, index) => (
                    <p key={index + 500} className="numbercircle">
                      {i}
                    </p> 
                  ))}*/
                  }
                </Col>
              </Row>
              <Row>
                <Col lg="12">
                  <Table bordered responsive>
                    <thead>
                      <tr>
                        <th>Bought By</th>
                        <th>Numbers</th>
                      </tr>
                    </thead>
                    <tbody>
                      {lotterylist && lotterylist.length == 0 && (
                        <tr>
                          <td colspan={12} style={{ textAlign: "center" }}>
                            {" "}
                            <h4> No Data !</h4>{" "}
                          </td>
                        </tr>
                      )}
                      {lottery?.lotteryType !== "3" ? (
                        <>
                          {lotterylist &&
                            lotterylist.map((item) => (
                              <tr key={item.lotteryId}>
                                <td className="address-text">
                                  {item.useraddress}
                                </td>
                                <td className="row m-0 numbercirclediv">
                                  {" "}
                                  {item.ticketbought.map((i) => (
                                    <p className="numbercircle">{i}</p>
                                  ))}
                                </td>
                              </tr>
                            ))}
                        </>
                      ) : (
                        <>
                          {lotterylist &&
                            lotterylist.map((item) => {
                              const ticketDigits = item.ticketbought
                                .toString()
                                .split("")
                                .map(Number);
                              return (
                                <tr key={item.lotteryId}>
                                  <td className="address-text">
                                    {item.useraddress}
                                  </td>

                                  <td className="row m-0 numbercirclediv">
                                    {ticketDigits.map((digit, index) => (
                                      <p key={index} className="numbercircle">
                                        {digit}
                                      </p>
                                    ))}
                                  </td>
                                </tr>
                              );
                            })}
                        </>
                      )}
                    </tbody>
                  </Table>
                </Col>
              </Row>
            </div>
          </Container>
        )}
      </div>
    );
  }
}

export default LotteryDetails;
